<template>
  <div v-if="isList" class="content-wrapper">
    <div class="content pt-0">
      <bo-page-title />
      <div class="card">
        <div class="bg-white card-header">
          <div class="row align-items-center">
            <div class="col-md-auto">
              <h5 class="card-title font-weight-semibold mb-md-1 mb-lg-0">Daftar Pasien</h5>
            </div>
            <div class="col-md-auto">
              <div class="form-group mb-0">
                <div class="input-group">
                  <date-range-picker ref="picker" :locale-data="datePickerConfig.locale"
                    :autoApply="datePickerConfig.autoApply" v-model="dateRange" :opens="'right'"
                    :ranges="datePickerConfig.ranges" @update="updateValues">
                    <template v-slot:input="picker">
                      {{ picker.startDate | date }} - {{ picker.endDate | date }}
                    </template>
                  </date-range-picker>
                  <div class="input-group-append calendar-group">
                    <span class="input-group-text" id="basic-addon2"><i class="icon-calendar"></i></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="card-header">
          <div class="row">
            <div class="col-md-8 col-xxl-9">
              <div class="row gx-1">
                <div class="col-md-auto">
                  <div class="form-group mb-0">
                    <button @click="doRefreshData" data-toggle="modal" data-target="#FilterField" data-popup="tooltip"
                      class="btn btn-light" title="Muat Ulang" v-b-tooltip.hover.right.noninteractive><i
                        class="icon-spinner11"></i></button>
                  </div>
                </div>
                <div class="col-md">
                  <div class="row gx-1">

                    <div class="col-md-6 col-lg-4 col-xxl-3">
                      <b-form-group class="mb-0">
                        <v-select placeholder=" -- Pilih DPJP -- " @input="doFill" v-model="filter.dpjp"
                          :options="mDPJP" label="text" :reduce="v=>v.value"></v-select>
                      </b-form-group>
                    </div>


                    <div class="col-md-6 col-lg-4 col-xxl-3">
                      <b-form-group class="mb-0">
                        <v-select placeholder=" -- Pilih Status -- " @input="doFill" :clearable="true"
                          v-model="filter.status" :options="mOperasiStatus" label="text" :reduce="v=>v.value">
                        </v-select>
                      </b-form-group>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 col-xxl-3">
              <div class="form-group form-group-feedback form-group-feedback-left mb-0 d-flex">
                <input class="form-control" v-model="filter.search" @input="doSearch"
                  placeholder="Ketik Nama/No. RM Pasien" />
                <div class="form-control-feedback">
                  <i class="icon-search4 text-indigo"></i>
                </div>

                <b-button class="ml-1 d-inline-flex align-items-center" variant="outline-success" id="resetBtn"
                  @click="doResetData()">Reset</b-button>
              </div>
            </div>
          </div>
        </div>



        <div class="table-responsive">
          <table class="table table-bordered table-striped table-hover table-sm patient-table text-uppercase table-sm">
            <thead>
              <tr>
                <th>NO.</th>
                <th class="tbl-sticky-col tsc-left">NO. KEDATANGAN</th>
                <th class="tbl-sticky-col tsc-left tsc-left-last" style="--tsc-pos: 115px">NAMA/NO KTP</th>
                <th>DOKTER DPJP</th>
                <th>NO RM</th>
                <th>TANGGAL LAHIR</th>
                <th>USIA</th>
								<th>TINDAKAN OPERASI</th>
								<th>TINDAKAN ANESTESI</th>
                <th>DIAGNOSA</th>
                <th>JADWAL OPERASI</th>
                <th>DOKTER OPERATOR</th>
                <th>DOKTER ANESTESI</th>
                <th>PERAWAT</th>
                <th>STATUS</th>
                <th style="min-width: 100px;" class="text-center tbl-sticky-col tsc-right tsc-right-first">AKSI</th>
              </tr>
            </thead>

            <tbody v-if="(dataList||[]).length">
              <template v-for="(v,k) in (dataList||[])">
                <tr :key="k" :class="!v.aranr_ppjp && !v.aranr_perawat_pelaksana ? 'table-warning':''">
                  <td>{{(data.per_page*(data.current_page-1))+k+1}}</td>
                  <td class="tbl-sticky-col tsc-left">
                    <div class="text-success-700 font-weight-semibold">{{v.aranr_reg_code||"-"}}</div>
                  </td>
                  <td class="tbl-sticky-col tsc-left tsc-left-last" style="--tsc-pos: 115px">
                    <div>
                      <a href="javascript:;" @click="toDetail(v.aranr_id)" class="font-weight-semibold border-bottom"
                        v-b-tooltip.hover.noninteractive title="Periksa Pasien"> {{v.ap_fullname||"-"}}</a>
                      <br>
                      {{v.ap_code||"-"}}</div>
                    <small class="text-secondary" v-if="v.aranr_reg_date">Terdaftar
                      {{v.aranr_reg_date | moment("DD MMMM YYYY, HH:mm")}}</small>
                  </td>
                  <td>{{v.bu_full_name||"-"}}</td>
                  <td>
                    <div class="font-weight-semibold">{{v.ap_code||"-"}}</div>
                  </td>
                  <td>
                    <div class="font-weight-semibold" v-if="v.ap_dob">{{v.ap_dob | moment("DD MMMM YYYY") }}</div>
                    <span v-else> - </span>
                  </td>
                  <td>
                    <div class="font-weight-semibold">
                      {{v.ap_usia_with_ket || "-"}}
                    </div>
                  </td>
                  <td>
                    {{v.arano_tindakan_operasi||"-"}}
                  </td>
									<td>{{v.arano_jenis_new||v.arano_jenis||"-"}}</td>
                  <td>
                    {{v.arano_diagnosa||"-"}}                  
                  </td>
                  <td>
                    {{v.arano_jadwal_operasi | moment("DD MMMM YYYY, HH:mm")}} WIB
                  </td>
                  <td>
                    <span v-if="!(v.dokter_operator||[]).length"> - </span>
                    <ul v-else>
                      <li v-for="(vk,kk) in (v.dokter_operator||[])" :key="kk+'s'">{{vk}}</li>
                    </ul>
                  </td>
                  <td>
                    {{v.dokter_anastesi||"-"}}
                  </td>
                  <td>
                    {{v.perawat_anestesi||"-"}}
                  </td>
                  <td>
                    <div :class="`badge ${v.aranos_color} mb-1`">{{v.aranos_name}}</div>
                    <div v-if="v.arano_is_implant == 'Y'" :class="`badge bg-danger mb-1`">Implant</div>
										<div v-if="v.arano_is_implant == 'B'" v-b-tooltip.hover :title="v.arano_implant_cancel_reason" :class="`badge bg-danger mb-1`">Implant Dibatalkan</div>
                    <div v-if="v.arano_is_penyakit_menular == 'Y'" :class="`badge bg-danger mb-1`" v-b-tooltip.hover
                      :title="v.mranpm_name">Penyakit Menular</div>

                    <div v-if="!v.aranr_id" :class="`badge bg-danger mb-1`">Pasien Belum DiDaftarkan</div>

                    <div v-if="v.arano_isi_ubs == 'N'" :class="`badge bg-primary mb-1`">Pasien Pindah Ruang</div>
                  </td>
                  <td class="text-center tbl-sticky-col tsc-right tsc-right-first">

                    <template v-if="v.aranr_id">
                      <a href="javascript:;" @click="toDetail(v.aranr_id)" v-if="moduleRole('view')"
                        class="btn btn-icon rounded-round btn-sm alpha-info border-info"
                        v-b-tooltip.hover.noninteractive title="Lihat Detail">
                        <i class="icon-file-eye"></i>
                      </a>
                      <a href="javascript:;" @click="toInformConsent(v)" v-if="moduleRole('inform_consent')"
                        class="btn btn-icon rounded-round btn-sm alpha-indigo border-indigo text-indigo-800"
                        v-b-tooltip.hover.noninteractive title="Inform Consent">
                        <i class="fas fa-notes-medical"></i>
                      </a>
                      
                      <template v-if="v.arano_status != 99">

                      <a href="javascript:;" v-if="moduleRole('daftar_operasi') && v.arano_status < 3"
                        @click="jadwalkanOperasi(v)"
                        class="btn btn-icon rounded-round btn-sm alpha-warning border-warning text-warning-800"
                        v-b-tooltip.hover.noninteractive
                        :title="v.arano_is_registered == 'Y' ? 'Ubah Pendaftaran Operasi': 'Daftarkan Operasi'">
                        <i class="fa-solid fa-user-nurse"></i>
                      </a>
                      <a href="javascript:;" @click="toMenu(v,'RanapCeklistKeselamatanAnestesi')"
                        v-if="moduleRole('keselamatan_pasien') && v.arano_status >= sOpTerjadwal"
                        class="btn btn-icon rounded-round btn-sm alpha-success border-success"
                        v-b-tooltip.hover.noninteractive title="Form Checklist Keselamatan Pasien"><i
                          class="icon-file-plus"></i></a>

                      <a href="javascript:;" @click="toMenu(v,'KajianPraAnestesi')"
                        v-if="moduleRole('kajian_pra_anestesi')"
                        class="btn btn-icon rounded-round btn-sm alpha-danger border-danger"
                        v-b-tooltip.hover.noninteractive title="Kajian Pra Anestesi"><i class="icon-file-plus"></i></a>

                      <a href="javascript:;" @click="toMenu(v,'TransferIntraBedah')" v-if="moduleRole('transfer_intra')"
                        class="btn btn-icon rounded-round btn-sm alpha-success border-success text-success-800"
                        v-b-tooltip.hover.noninteractive title="Form Transfer Intra Bedah"><i
                          class="icon-pencil4"></i></a>

                      <a href="javascript:;" @click="toImplant(v)"
                        v-if="moduleRole('transfer_intra_implant') && v.arano_is_implant == 'Y'"
                        class="btn btn-icon rounded-round btn-sm alpha-success border-success text-success-800"
                        v-b-tooltip.hover.noninteractive title="Form Transfer Implant"><i class="icon-pencil4"></i></a>

                        
                      <a href="javascript:;" @click="cancelOperasi(v)"
                        v-if="moduleRole('batalkan_operasi') && !v.arano_operasi_end"
                        class="btn btn-icon rounded-round btn-sm alpha-danger border-danger text-danger-800 mb-1"
                        v-b-tooltip.hover.noninteractive title="Batalkan Operasi">
                        <i class="icon-close2"></i>
                      </a>

                      <a href="javascript:;" v-if="v.transfer_ke_ranap" class="btn btn-icon rounded-round btn-sm alpha-warning border-warning" v-b-tooltip.hover.noninteractive="'Tanda Tangan Transfer UBS ke Ranap'" @click.prevent="TTDBedahRanap({arantbr_id: v.transfer_ke_ranap, arano_id: v.arano_id})">
                        <i class="fa-solid fa-file-signature"></i>
                      </a>
                      
                      </template>
                    </template>
                    <span v-else> - </span>

                  </td>

                </tr>
              </template>
            </tbody>
            <tbody v-if="data.data.length == 0">
              <tr>
                <td colspan="99">
                  <div class="text-center">
                    <h4 align="center"><span
                        v-if="filter.status || filter.search || filter.startDate || filter.endDate">Hasil pencarian
                        tidak ditemukan</span>
                      <span v-else>Belum Ada Data {{ pageTitle }} yang terdaftar</span></h4>
                  </div>
                </td>
              </tr>
            </tbody>
            <tbody v-if="!data.data">
              <tr>
                <td colspan="99">
                  <div class="skeletal-comp"></div>
                </td>
              </tr>
              <tr>
                <td colspan="99">
                  <div class="skeletal-comp"></div>
                </td>
              </tr>
              <tr>
                <td colspan="99">
                  <div class="skeletal-comp"></div>
                </td>
              </tr>
            </tbody>
          </table>


          <div
            :class="(dataList||[]).length && !loadingTable && dataList ? 'table-scroll-actions' : 'table-scroll-actions d-none'">
            <a href="javascript:;" data-scroll="left"
              class="btn btn-icon alpha-info border-info text-info-800 rounded-round">
              <i class="icon-chevron-left"></i>
            </a>
            <a href="javascript:;" data-scroll="right"
              class="btn btn-icon alpha-info border-info text-info-800 rounded-round">
              <i class="icon-chevron-right"></i>
            </a>
          </div>
        </div>

        <b-card-footer class="pagination-custom" v-if="(dataList||[]).length&&data.total>data.per_page">
          <b-pagination class="mb-0" v-model="pageNo" :per-page="data.per_page" :total-rows="data.total" />
        </b-card-footer>

      </div>
    </div>
    <validation-observer ref="VFormSubmitJadwalOperasi">
      <b-modal v-model="openJadwalOperasi" ok-title="Submit" @ok.prevent="submitJadwalOperasi" size="lg"
        no-close-on-backdrop class="text-center" :title="'Daftarkan Operasi'">
        <table class="table table-sm table-bordered">
          <thead>
            <tr>
              <th colspan="6">Informasi Pasien</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colspan="2">
                <div class="result_tab">
                  <h4>Nama</h4>
                  <p>{{rowEdit.ap_fullname||"-"}}</p>
                </div>
              </td>
              <td colspan="2">
                <div class="result_tab">
                  <h4>Tanggal Lahir / Umur</h4>
                  <p>{{rowEdit.ap_dob | moment("DD MMMM YYYY") }} / {{rowEdit.ap_usia}} Tahun</p>
                </div>
              </td>
              <td colspan="2">
                <div class="result_tab">
                  <h4>Jenis Kelamin</h4>
                  <p>{{rowEdit.ap_gender == 1 ? "Laki-Laki":"Perempuan"}}</p>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="2">
                <div class="result_tab">
                  <h4>Ruangan</h4>
                  <p>{{rowEdit.mranb_name||"-"}}</p>
                </div>
              </td>
              <td colspan="2">
                <div class="result_tab">
                  <h4>Kamar / No. Tempat Tidur / Kelas</h4>
                  <p>{{rowEdit.mranbed_name||"-"}} / {{rowEdit.mrankel_name||"-"}}</p>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="2">
                <div class="result_tab">
                  <h4>Jenis Cara Bayar</h4>
                  <p>{{getConfigDynamic(Config.mr.statusJaminan,rowEdit.aranres_jaminan)||"-"}}</p>
                </div>
              </td>
              <td colspan="2">
                <div class="result_tab">
                  <h4>Alergi</h4>
                  <ul v-if="(rowEdit.alergi||[]).length" class="pl-3">
                    <li v-for="(v,k) in (rowEdit.alergi||[])" :key="k+'alergi'">
                      {{v.name||"-"}} - <span class="font-weight-semibold">{{v.jenis||"-"}}</span>
                    </li>
                  </ul>
                  <span v-else>Tidak ada Alergi</span>
                </div>
              </td>
              <td colspan="2">
                <div class="result_tab">
                  <h4>Diagnosa Masuk</h4>
                  <p>{{rowEdit.arano_diagnosa||"-"}}</p>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="row mt-2">
          <div class="col-md-6 mt-2">
            <table class="table table-sm table-bordered">
              <thead>
                <tr>
                  <th>Dokter Operator</th>
                  <th>Aksi</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(v,k) in (rowEdit.arano_dokter_operator||[])" :key="k+'op'">
                  <td>
                    <div>

                      <v-select placeholder="Dokter Operator" v-model="rowEdit.arano_dokter_operator[k]['dokter']"
                        :options="mDPJP" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                      <VValidate :name="'Dokter Operator #'+(k+1)" v-model="rowEdit.arano_dokter_operator[k]['dokter']"
                        :rules="{required: 1}" />

                      <div class="mt-2">
                        <template>
                          <div class="custom-control custom-radio custom-control-inline">
                            <input v-model="operatorUtama" :value="rowEdit.arano_dokter_operator[k]['dokter']"
                              type="radio" name="primaryOperatorUtama" class="custom-control-input"
                              :id="`primaryOperatorUtama${k}`" />
                              <label class="custom-control-label"
                              :for="`primaryOperatorUtama${k}`">
                                Tandai Sebagai Dokter Operator Utama
                              </label>
                          </div>

                          <VValidate :name="'Operator Utama'" v-model="operatorUtama" :rules="{required:1}" />
                        </template>
                      </div>
                    </div>
                  </td>
                  <td>
                    <a href="javascript:;" @click="removeOperator(k)"
                      class="btn btn-icon rounded-round btn-sm alpha-warning border-warning text-warning-800"
                      v-b-tooltip.hover.noninteractive title="Hapus">
                      <i class="fa-solid fa-trash"></i>
                    </a>
                  </td>
                </tr>
              </tbody>

              <tfoot>
                <tr>
                  <td colspan="3" class="text-center">
                    <a href="javascript:;" @click="addOperator"
                      class="btn btn-info btn-sm btn-labeled btn-labeled-left">
                      <b><i class="icon-plus2"></i></b>
                      <span>Tambah</span>
                    </a>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>

          <div class="col-md-6 mt-2">
            <table class="table table-sm table-bordered">
              <thead>
                <tr>
                  <th colspan="2">Dokter Anestesi</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <v-select placeholder="Dokter Anestesi" v-model="rowEdit.arano_dokter_anastesi"
                      :options="mDokterAnestesi" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                    <VValidate name="Dokter Anestesi" v-model="rowEdit.arano_dokter_anastesi" :rules="{required: 1}" />
                  </td>
                </tr>
              </tbody>
            </table>

            <div class="row">
              <div class="col-md-12 mt-2">
                <div class="form-group">
                  <label for="">Apakah Pasien Memiliki Penyakit Menular?<strong class="text-danger">*</strong></label>

                  <b-form-radio-group id="apakah_pasien_punya_penyakit" :options="Config.mr.yesNoOpt"
                    v-model="rowEdit.arano_is_penyakit_menular" />
                  <VValidate name="Penyakit Menular" v-model="rowEdit.arano_is_penyakit_menular"
                    :rules="{required: 1}" />
                </div>

                <div class="form-group" v-if="rowEdit.arano_is_penyakit_menular == 'Y'">
                  <v-select placeholder="-- Pilih Salah Satu --" v-model="rowEdit.arano_penyakit_menular"
                    :options="mRanapPenyakitMenular" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                  <VValidate name="Nama Penyakit Menular" v-model="rowEdit.arano_penyakit_menular"
                    :rules="{required: 1}" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-modal>
    </validation-observer>

    <b-modal v-model="openInformConsent" size="xl" hide-footer class="text-center"
      :title="'Persetujuan Tindakan Kedokteran (Informed Consent)'">
      <div class="card-body">
        <div class="row row-gutter">
          <div class="col-md-12 col-12 mb-2" v-if="(informConcent||[]).length">
            <b-form-group class="mb-0">
              <label>Dokter Pelaksana</label>
              <v-select placeholder=" -- Pilih Dokter Pelaksana -- " v-model="rowEdit.dokter_pelaksana"
                :options="mDPJP" label="text" :reduce="v=>v.text"></v-select>
            </b-form-group>
          </div>

          <div class="col-md-4" v-for="(v,k) in (informConcent||[])" :key="k">
            <div class="wrap_line mb-0 h-100">
              <div class="mb-2">
                <h3 class="pb-0">[{{v.aranic_created_on}}] {{v.aranic_name||"-"}}
                <span v-if="v.is_cancel" :class="`badge badge-danger text-white`">Dibatalkan</span>
                </h3>
              </div>
              <div>
                <a href="javascript:;" @click="openTemplateIC(v)"
                  class="btn alpha-success border-success text-success-800 mb-1">
                  <b><i class="icon-download"></i></b>
                  <span class="ml-2">Unduh Template Dokumen</span>
                </a>
                <Uploader @input="uploadDokStatus(v,k)" v-model="v.aranic_file" isDocument type="doc_only" />
              </div>

              <span class="badge badge-primary" v-if="v.aranic_created_on == 'PetugasRadiologi'">
                Radiologi
              </span>
              <span class="badge badge-primary" v-else>
                Dokter
              </span>

              <div class="d-flex align-items-center justify-content-between mt-2">
                <span v-if="v.aranic_status == 'DONE' || v.aranic_file" class="badge badge-success">Selesai</span>
                <span v-else class="badge badge-info">Unggah TTD</span>

                <div>
                  <a v-if="v.aranic_file" :href="uploader(v.aranic_file)" target="_blank"
                    class="btn btn-sm alpha-success border-success btn-icon rounded-round text-success-700"
                    v-b-tooltip.hover.right.noninteractive title="Unduh Dokumen"><i class="icon-download"></i></a>

                  <a href="javascript:;" @click="openInformConsentDetail(v)" data-toggle="modal"
                    class="btn btn-sm alpha-info border-info btn-icon rounded-round text-info-700"
                    v-b-tooltip.hover.right.noninteractive title="Lihat Detail"><i class="icon-eye"></i></a>
                </div>
              </div>

              <span :class="v.aranic_is_pasien_setuju == 'Y' ? 'badge badge-success':'badge badge-danger'">
              {{v.aranic_is_pasien_setuju == 'Y' ? 'Tindakan Disetujui':'Tindakan Ditolak'}} Oleh 
              {{v.aranic_persetujuan_tindakan == 'P' ? 'Pasien':'Penanggung Jawab'}}
              </span>

            </div>
          </div>
          <div class="col-md-4" v-if="!(informConcent||[]).length">
            <p>Tidak ada Data Inform Consent</p>
          </div>
        </div>
      </div>
    </b-modal>

    <b-modal v-model="openModalICDetail" :title="'Detail Informed Consent'" size="xl" ok-only>
      <table class="table table-bordered table-sm table-input">
        <thead>
          <tr>
            <td colspan="3">
              <div class="result_tab">
                <h4>Nama Tindakan</h4>
                <p>{{rowIC.aranic_name}}</p>
              </div>
            </td>
          </tr>
          <tr>
            <th width="24">No.</th>
            <th>Jenis Informasi</th>
            <th>Isi Informasi</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1</td>
            <td>Diagnosis<br />(Diagnosis Kerja & Diagnosis Banding)</td>
            <td>
              {{rowIC.aranic_diagnosis||"-"}}
            </td>
          </tr>
          <tr>
            <td>2</td>
            <td>Dasar Diagnosis</td>
            <td>
              {{rowIC.aranic_dasar_diagnosis||"-"}}
            </td>
          </tr>
          <tr>
            <td>3</td>
            <td>Tindakan Kedokteran</td>
            <td>
              {{rowIC.aranic_tindakan_dokter||"-"}}
            </td>
          </tr>
          <tr>
            <td>4</td>
            <td>Indikasi Tindakan</td>
            <td>
              {{rowIC.aranic_indikasi_tindakan||"-"}}
            </td>
          </tr>
          <tr>
            <td>5</td>
            <td>Tata Cara</td>
            <td>
              {{rowIC.aranic_tata_cara||"-"}}
            </td>
          </tr>
          <tr>
            <td>6</td>
            <td>Tujuan</td>
            <td>
              {{rowIC.aranic_tujuan||"-"}}
            </td>
          </tr>
          <tr>
            <td>7</td>
            <td>Risiko</td>
            <td>
              {{rowIC.aranic_risiko||"-"}}
            </td>
          </tr>
          <tr>
            <td>8</td>
            <td>Komplikasi</td>
            <td>
              {{rowIC.aranic_kompilasi||"-"}}
            </td>
          </tr>
          <tr>
            <td>9</td>
            <td>Prognosis</td>
            <td>
              {{rowIC.aranic_prognosis||"-"}}
            </td>
          </tr>
          <tr>
            <td>10</td>
            <td>Alternatif & Risiko</td>
            <td>
              {{rowIC.aranic_alternatif||"-"}}
            </td>
          </tr>
          <tr>
            <td>11</td>
            <td>Lain-lain</td>
            <td>
              {{rowIC.aranic_lainnya||"-"}}
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td width="50%" colspan="2">
              <div class="result_tab">
                <h4>Apakah pasien/pendamping pasien menyetujui tindakan?</h4>
                <p v-if="rowIC.aranic_is_pasien_setuju == 'Y'">Tindakan disetujui</p>
                <p v-else>Tindakan ditolak</p>
              </div>
            </td>
            <td width="50%">
              <div class="result_tab">
                <h4>Persetujuan tindakan diberikan kepada</h4>
                <p v-if="rowIC.aranic_persetujuan_tindakan == 'P'">Pasien</p>
                <p v-else>Penanggung Jawab</p>
              </div>
            </td>
          </tr>
        </tfoot>
      </table>
    </b-modal>


    <validation-observer ref="VFormModal">
      <b-modal v-model="openTemplateICModal" @ok.prevent="unduhtemplateIC(rowIC)" ok-title="Unduh Template"
        title="Unduh Template Dokumen" size="lg">
        <table class="table table-bordered table-sm text-uppercase">
          <tbody>
            <tr>
              <td colspan="3" class="bg-primary">Data Pasien</td>
            </tr>
            <tr>
              <td width="33%">
                <div class="result_tab">
                  <h4>Nama Pasien</h4>
                  <p>{{rowEdit.ap_fullname||"-"}}</p>
                </div>
              </td>
              <td width="33%">
                <div class="result_tab">
                  <h4>Usia</h4>
                  <p>{{rowEdit.ap_usia||"-"}}</p>
                </div>
              </td>
              <td width="33%">
                <div class="result_tab">
                  <h4>Alamat</h4>
                  <p>{{rowEdit.ap_address||"-"}}</p>
                </div>
              </td>
            </tr>
            <!-- == 'PJ'-->
            <template v-if="rowIC.aranic_persetujuan_tindakan == 'PJ'">
              <tr>
                <td colspan="3" class="bg-primary">Data Penanggung Jawab</td>
              </tr>
              <tr>
                <td width="33%">
                  <div class="result_tab">
                    <h4>Nama Penanggung Jawab</h4>
                    <b-form-input v-model="rowIC.aranic_nama_pj" type="text" name="NamaPenanggung" id="NamaPenanggung"
                      class="form-control" placeholder="Nama Penanggung Jawab" />
                    <VValidate name="Nama Penanggung Jawab" v-model="rowIC.aranic_nama_pj" :rules="{required: 1}" />
                  </div>
                </td>
                <td width="33%">
                  <div class="result_tab">
                    <h4>Usia Penanggung Jawab</h4>
                    <b-form-input v-model="rowIC.aranic_usia_pj" type="text" name="UsiaPenanggung" id="UsiaPenanggung"
                      class="form-control" placeholder="Usia Penanggung Jawab" />
                    <VValidate name="Usia Penanggung Jawab" v-model="rowIC.aranic_usia_pj" :rules="{required: 1}" />
                  </div>
                </td>
                <td width="33%">
                  <div class="result_tab">
                    <h4>Alamat Penanggung Jawab</h4>
                    <b-form-input v-model="rowIC.aranic_address_pj" type="text" name="alamat" id="alamat"
                      class="form-control" placeholder="Alamat Penanggung Jawab" />
                    <VValidate name="Alamat Penanggung Jawab" v-model="rowIC.aranic_address_pj"
                      :rules="{required: 1}" />
                  </div>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </b-modal>
    </validation-observer>


    <validation-observer ref="VFormImplant">
      <b-modal v-model="openImplant" @ok.prevent="submitTTDImplant()" ok-title="Simpan" title="Dokumen Operasi"
        size="xl">
        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label for="">Nama Penanggung Jawab</label>
              <b-form-input v-model="rowImplant.arano_implant_name" type="text" class="form-control"
                placeholder="Nama Penanggung Jawab" />
              <VValidate :name="'Nama Penanggung Jawab'" v-model="rowImplant.arano_implant_name"
                :rules="{required: 1, min:2, max:128}" />
            </div>

            <div class="form-group">
              <label for="">No Telepon Penanggung Jawab</label>
              <b-form-input v-model="rowImplant.arano_implant_no_telp" :formatter="numberOnly" type="text"
                class="form-control" placeholder="No Telepon Penanggung Jawab" />
              <VValidate :name="'No Telepon Penanggung Jawab'" v-model="rowImplant.arano_implant_no_telp"
                :rules="{required: 1, min:6, max:20}" />
            </div>

            <div class="form-group">
              <label for="">Alamat Penanggung Jawab</label>
              <b-form-textarea v-model="rowImplant.arano_implant_alamat" type="text" class="form-control"
                placeholder="Alamat Penanggung Jawab" />
              <VValidate :name="'Alamat Penanggung Jawab'" v-model="rowImplant.arano_implant_alamat"
                :rules="{required: 1, min:2, max:128}" />
            </div>

            <div class="form-group">
              <div class="signing-element mt-1" data-target-id="ttdPengirim">
                <VueSignaturePad class="sign-container" width="100%" height="160px" ref="ttdImplant" />
                <div class="mt-2">
                  <button type="button" @click="undoTTD('ttdImplant','arano_ttd_implant')"
                    class="btn btn-sm btn-danger btn-labeled btn-labeled-left se-cleaner">
                    <b><i class="icon-cross3"></i></b>
                    <span>Clear</span>
                  </button>

                  <button type="button" @click="output('ttdImplant','arano_ttd_implant')"
                    class="btn btn-sm btn-success btn-labeled btn-labeled-left se-cleaner ml-2">
                    <b><i class="icon-checkmark2"></i></b>
                    <span>Simpan</span>
                  </button>
                </div>

                <VValidate name="Tanda Tangan Keluarga" v-model="rowImplant.arano_ttd_implant" :rules="{required:1}" />
              </div>
            </div>
          </div>
          <div class="col-md-8">
            <div class="document-framer">
              <div class="df-content" v-html="rowImplant.arano_implant_temp">
              </div>
            </div>
          </div>
        </div>
      </b-modal>
    </validation-observer>

    <b-modal id="ttd_bedah_ranap" size="xl" title="Tanda Tangan Form Bedah Ke Ranap" hide-footer>
      <ModalTTDBedahRanap v-if="dataModal.arantbr_id" :arano_id="dataModal.arano_id" :arantbr_id="dataModal.arantbr_id" @closeModal="closeModalTTDBedahRanap" @successSubmit="successTTDBedahRanap" @resize="resize" />
    </b-modal>

  </div>
  <div v-else>
    <template v-if="typeMenu == 'TransferIntraBedah'">
      <TransferIntraBedah />
    </template>
    <template v-if="typeMenu == 'KajianPraAnestesi'">
      <KajianPraAnestesi />
    </template>
    <template v-if="typeMenu == 'RanapCeklistKeselamatanAnestesi'">
      <RanapCeklistKeselamatanAnestesi />
    </template>
  </div>
</template>

<script>
  import GlobalVue from '@/libs/Global.vue'
  import DateRangePicker from 'vue2-daterange-picker'
  import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

  const moment = require('moment')
  
  // import Datepicker from 'vuejs-datepicker'
  import TransferIntraBedah from './TransferIntraBedah.vue'
  import KajianPraAnestesi from './KajianPraAnestesi.vue'
  import RanapCeklistKeselamatanAnestesi from './RanapCeklistKeselamatanAnestesi.vue'
  import ModalTTDBedahRanap from './ModalTTDBedahRanap.vue'



  const _ = global._
  import $ from 'jquery'
  import Gen from '@/libs/Gen.js'

  export default {
      extends: GlobalVue,
      components:{
          DateRangePicker,TransferIntraBedah,KajianPraAnestesi,RanapCeklistKeselamatanAnestesi,ModalTTDBedahRanap
      },
      computed:{
          isDetail(){
              return this.$route.query.isDetail
          },
          isDoc(){
              return this.$route.query.isDoc
          },
          dokterOperator(){
            let dokter = []
            for(let i = 0; i < (this.rowEdit.arano_dokter_operator||[]).length; i++){
              dokter.push(this.rowEdit.arano_dokter_operator[i]['dokter'])
            }
            return dokter
          },
          typeMenu(){
            return this.$route.query.type
          }
      },
      data(){
          return {
              idKey:'aranres_id',
              datePickerConfig: {
                  startDate: new Date(moment().subtract(6, 'days')),
                  endDate: new Date(moment().add(6, 'days')),
                  autoApply: true,
                  ranges: {
                      'Hari Ini': [new Date(), new Date()],
                      '7 Hari Terakhir': [new Date(moment().subtract(6, 'days')), new Date()],
                      '30 Hari Terakhir': [new Date(moment().subtract(29, 'days')), new Date()],
                  },
                  applyClass: 'btn-sm btn-primary',
                  cancelClass: 'btn-sm btn-light',
                  locale: {
                      applyLabel: 'Terapkan',
                      cancelLabel: 'Batal',
                      direction: 'ltr',
                      format: 'mm/dd/yyyy',
                      separator: ' - ',
                  }
              },
              dateRange: {
                  startDate: new Date(moment().subtract(6, 'days')),
                  endDate: new Date(moment().add(6, 'days')),
              },
              mDokterAnestesi: [],
              mRanapPenyakitMenular: [],
              mDPJP : [],
              rowEdit: {},
              openJadwalOperasi: false,
              operatorUtama: null,

              openInformConsent: false,
              informConcent: [],
              rowIC: {},
              openModalICDetail: false,
              dataPJ: {},
              openTemplateICModal: false,
              openImplant: false,
              rowImplant: {},
              mOperasiStatus: [],

              dataModal: {}
          }
      },
      mounted(){
          this.filter.dpjp = 'ALL'
          
          this.doConvertDate()      
          this.apiGet()
          
          if(this.isList){
              this.apiGetResume()
          }
              
          setTimeout(()=>{
              this.setSlide()
          },1000)
      },
      methods: {
        resize(){
          setTimeout(()=>{
            $(".sign-container canvas").attr("width",400)
            $(".sign-container canvas").attr("height",160)
          },200)
        },
        closeModalTTDBedahRanap(){
          this.$set(this, 'dataModal', {})
          this.$bvModal.hide('ttd_bedah_ranap')
        },
        successTTDBedahRanap(){
          this.closeModalTTDBedahRanap()
          this.getList()
        },
        TTDBedahRanap(val){
          this.$set(this.dataModal, 'arantbr_id', val.arantbr_id)
          this.$set(this.dataModal, 'arano_id', val.arano_id)
          this.$bvModal.show('ttd_bedah_ranap')
        },
        cancelOperasi(row){
          this.rowEdit = row

          this.$swal({
            icon: 'warning',
            title: 'Apakah Anda Yakin akan membatalkan operasi pasien ini?',
            showCancelButton: true,
            html: `<textarea id="reason" placeholder="Alasan Batal" rows="4" class="form-control"></textarea>`,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Tidak, kembali'
          }).then(result => {
            if (result.value) {
              var val = document.getElementById('reason').value
              this.rowEdit.type = 'batal-operasi'
              this.rowEdit.alasan_batal = val
              this.loadingOverlay = true
              Gen.apiRest(
                  "/do/"+'RanapOperasiPerawatBedah',
                  {data: this.rowEdit}, 
                  "POST"
              ).then(res=>{
                  if((+this.$route.query.page||1) != 1){
                      this.doResetData()
                  }else{
                      this.apiGet()
                  }
                  this.loadingOverlay = false
                  this.$swal({
                      title: `Berhasil`,
                      text: 'Berhasil Membatalkan Operasi Pasien',
                      icon: 'success',
                  })
              })
            }
          })
        },
        toDetail(regID){
          this.$router.push({ name: 'RanapPasienOperasi', params: {pageSlug: regID}}).catch(()=>{})
        },
        toImplant(row){
          let data = row
          data.type = 'get-implant'
          this.loadingOverlay = true
          Gen.apiRest(
            "/do/"+this.modulePage,
              {data: data}, 
              "POST"
          ).then(res=>{
            let resp = res.data
            // rowImplant
            this.loadingOverlay = false
            this.rowImplant = resp.row
            this.openImplant = true
        
            setTimeout(()=>{
              $(".sign-container canvas").attr("width",400)
              $(".sign-container canvas").attr("height",160)                
              this.$nextTick(() => {
                if(this.$refs['ttdImplant']){
                  this.$refs['ttdImplant'].fromDataURL(this.rowImplant.arano_ttd_implant)
                } 
              })
            },200)
          })  

        },
        undoTTD(refs,field){
          this.$refs[refs].clearSignature()
          this.rowImplant[field] = null
          this.autoSaveImplant(this.rowImplant)
        },
        output(refs,field) {
          let { isEmpty, data } = this.$refs[refs].saveSignature()
          if(!isEmpty){
            this.rowImplant[field] = data
            this.autoSaveImplant(this.rowImplant)
          }
        },
        autoSaveImplant: _.debounce(function (data) {
          data.type = 'get-implant'
          
          Gen.apiRest(
              "/do/"+this.modulePage,
              {data:data}, 
              "POST"
          ).then(res=>{
            let resp = res.data
            this.rowImplant = resp.row
          })
        },1000),


        submitTTDImplant(){
          this.$refs['VFormImplant'].validate().then(success=>{
            if(success){
              this.loadingOverlay = true
              let data = this.rowImplant
              data.type = 'submit-implant'
              Gen.apiRest(
                  "/do/"+this.modulePage,
                  {data: data}, 
                  "POST"
              ).then(res=>{
                  let resp = res.data
                  this.loadingOverlay = false
                  this.$swal({
                      title: resp.message,
                      icon: "success",
                      confirmButtonText: 'Ok',
                      allowOutsideClick: false,
                      allowEscapeKey: false
                  }).then(result => {
                      if(result.value){
                        this.openImplant = false
                        this.apiGet()
                      }
                  })
              })
            }
          })
        },

        toInformConsent(row){
          let data = row
          data.type = 'get-data-inform-consent'
          this.loadingOverlay = true
          Gen.apiRest(
              "/do/"+this.modulePage,
              {data: data}, 
              "POST"
          ).then(res=>{
            let resp = res.data
            this.informConcent  = resp.informConcent
            this.dataPJ = resp.dataPJ
            this.rowEdit = row
            this.$set(this.rowEdit, 'dokter_pelaksana', this.rowEdit.dokter_pelaksana||row.bu_full_name)


            this.loadingOverlay = false
            this.openInformConsent = true
          })  
        },
        openInformConsentDetail(v){
            Gen.apiRest('/do/' + this.modulePage, {
                data: {
                  type: 'get-data-ic',
                  id: v.aranic_id,
                }
            }, 'POST').then(res => {
                this.rowIC = res.data.row 
                this.openModalICDetail = true                   
            })
        },
        openTemplateIC(v){
          this.rowIC = v
          this.rowIC.aranic_nama_pj = this.rowIC.aranic_nama_pj||this.rowEdit.ap_fullname
          this.rowIC.aranic_address_pj = this.rowIC.aranic_address_pj||this.rowEdit.ap_address
          this.rowIC.aranic_usia_pj = this.rowIC.aranic_usia_pj||this.rowEdit.ap_usia_with_ket
          this.openTemplateICModal = true
        },
        uploadDok(v,k=''){
          Gen.apiRest('/do/' + this.modulePage, {
            data: {
                type: 'update-data-ic',
                id: v.aranic_id,
                aranic_file: v.aranic_file,
                aranic_nama_pj: v.aranic_nama_pj,
                aranic_address_pj: v.aranic_address_pj,
                aranic_usia_pj: v.aranic_usia_pj,
            }
          }, 'POST').then(res => {
            if(k){
                this.informConcent[k]['aranic_status'] = 'DONE' 
            }
          })
        },
        uploadDokStatus(v,k=''){
          Gen.apiRest('/do/' + this.modulePage, {
            data: {
                type: 'update-data-ic',
                id: v.aranic_id,
                aranic_file: v.aranic_file,
                aranic_nama_pj: v.aranic_nama_pj,
                aranic_address_pj: v.aranic_address_pj,
                aranic_usia_pj: v.aranic_usia_pj,
                aranic_status: 'DONE'
            }
          }, 'POST').then(res => {
              this.informConcent[k]['aranic_status'] = 'DONE' 
          })
        },
        unduhtemplateIC(v){
            this.$refs['VFormModal'].validate().then(success => {
                if(success){
                    this.uploadDok(v)                
                    setTimeout(()=>{
                        this.openTemplateICModal = false
                        let data = {exptype: 'xlsx', type: "export", id: v.aranic_id, regID: this.rowEdit.arano_aranr_id, dokter_pelaksana: this.rowEdit.dokter_pelaksana}
                        let self = this
                        self.loadingOverlay = true
                        $.ajax({
                            type: "POST",
                            url: process.env.VUE_APP_API_URL + `/dokumen-ttd/${'InformConsentRanap'}?token=IXs1029102asoaksoas102901290`,
                            data: data,
                            cache: false,
                            xhrFields:{
                                responseType: 'blob'
                            },
                            success: data => 
                            {
                                self.loadingOverlay = false
                                var link = document.createElement('a')
                                link.href = window.URL.createObjectURL(data)
                                link.download = `${'Inform-consent'}-${this.rowEdit.ap_fullname}-${moment().format("YYYY-MM-DD")}-${this.rowEdit.aranr_reg_code}.pdf`
                                link.click()
                            },
                            fail: data => {
                                self.loadingOverlay = false
                                alert('Not downloaded')
                            }
                        })
                    },2000)
                    
                }
            })
        },



        toMenu(v, menu){
          this.$router.push(
          {
            name: this.modulePage, 
            params: {pageSlug : v.arano_id}, 
            query: {type : menu, regId: v.arano_aranr_id} 
          })
        },
        addOperator(){
          this.rowEdit.arano_dokter_operator.push({
            dokter: null,
            is_primary: null, 
          })
        },
        removeOperator(k){
          this.rowEdit.arano_dokter_operator.splice(k,1)
        },
        jadwalkanOperasi(row){
          let data = row
          data.type = 'get-data'
          this.loadingOverlay = true
          Gen.apiRest(
              "/do/"+this.modulePage,
              {data: data}, 
              "POST"
          ).then(res=>{
            let resp = res.data
            this.loadingOverlay = false   
            this.openJadwalOperasi = true  
            this.rowEdit = resp.row
            this.operatorUtama = resp.row.arano_dokter_operator_utama
          })  
        },
        setSlide(){
            const responsiveTable = document.querySelector('.table-responsive')
            if(responsiveTable){
                const responsiveTableScroll = responsiveTable.querySelector('.table-scroll-actions')
                const responsiveTableScrollActions = responsiveTable.querySelectorAll('.btn')
                if(responsiveTableScroll){
                    // responsiveTableScroll.style.top = (document.documentElement.scrollTop + (window.innerHeight / 1.5) - 32)+'px'
                    Array.from(responsiveTableScrollActions).forEach(action => {
                        action.addEventListener('click', e => {
                            const scrollAmount = action.dataset.scroll == 'right' ? responsiveTable.scrollLeft + 100 : responsiveTable.scrollLeft - 100
                              
                            responsiveTable.scroll({
                                left: scrollAmount,
                                behavior: 'smooth'
                            })
                        })
                    })
                }
            }
        },
        doResetData(){
            this.doReset()
            this.dateRange =  {
                startDate: new Date(moment().subtract(6, 'days')),
                endDate: new Date(moment().add(6, 'days')),
            }
            this.doConvertDate()
        },
        doRefreshData(){
            this.apiGet()
            window.scrollTo(0,0)
        },
        apiGetResume(){
            let paramsQuery = Object.keys(this.$route.query).length ? this.apiParams : this.$route.query
            Gen.apiRest(
                "/get/"+this.modulePage+'/resume', 
                {
                params: Object.assign({}, paramsQuery||{})
                }
            ).then(res=>{
                _.forEach(res.data, (v,k)=>{
                    this.$set(this, k, v)
                })
            })
        },
        doConvertDate(){
            this.filter.startDate = moment(this.dateRange.startDate).format('YYYY-MM-DD')
            this.filter.endDate = moment(this.dateRange.endDate).format('YYYY-MM-DD')
        },
        doPageOne(){
            this.filter.page = 1
        },
        doFill(){
            this.doPageOne()
            this.doFilter()
        },
        updateValues(e){
            this.doConvertDate()
            this.doFill()
        },
        doSearch: _.debounce(function(){
            this.doPageOne()
            this.doFilter()
        },500),
        getConfigDynamic(master,value){
            let text = ''
            if(value){
                let index = (master||[]).findIndex(x => x.value == value)
                if(index !== -1){
                    text = master[index]['text']
                }
            }
            return text
        },
        to24Hours(date){
            return moment(date).add(24, 'hours').format('YYYY-MM-DD')
        },
        submitJadwalOperasi(){
          this.$refs['VFormSubmitJadwalOperasi'].validate().then(success=>{
            if(!(this.rowEdit.arano_dokter_operator||[]).length){
              return  this.$swal({
                icon: 'warning',
                title: 'Tidak ada Dokter Operator',
              })
            }
            if(success){
              let data = this.rowEdit
              for (let i = 0; i < (this.rowEdit.arano_dokter_operator || []).length; i++) {
                this.rowEdit.arano_dokter_operator[i]['is_primary'] = null
              }

              data.arano_dokter_operator = this.rowEdit.arano_dokter_operator
              if ((data.arano_dokter_operator || []).length) {
                let indexPrimary2 = 
                this.rowEdit.arano_dokter_operator.findIndex(x => x.dokter == this.operatorUtama)
                if(indexPrimary2 == -1){
                  this.operatorUtama = this.rowEdit.arano_dokter_operator[0]['dokter']
                  indexPrimary2 = indexPrimary2 == -1 ? 0 : indexPrimary2
                }
                data.arano_dokter_operator[indexPrimary2]['is_primary'] = 'Y'
              }

              data.arano_dokter_operator_arr = this.dokterOperator
              data.arano_dokter_operator_utama = this.operatorUtama

              data.type = 'daftarkan-operasi'
                     
              Gen.apiRest(
                  "/do/"+this.modulePage,
                  {data: data}, 
                  "POST"
              ).then(res=>{
                  let resp = res.data
                  this.loadingOverlay = false
                  this.$swal({
                      title: resp.message,
                      icon: "success",
                      confirmButtonText: 'Ok',
                      allowOutsideClick: false,
                      allowEscapeKey: false
                  }).then(result => {
                      if(result.value){
                        this.openJadwalOperasi = false
                        this.apiGet()
                      }
                  })
              })
            }
          })
        }
      },
      filters: {
          date(val) {
              return val ? moment(val).format("D MMM YYYY") : ""
          }
      },
      watch:{
        $route(){
            this.apiGet()
            
            if(this.isList){
                this.apiGetResume()
            }
            
            setTimeout(()=>{
                if(this.isList){
                    this.setSlide()
                }
            },1000)
        },
        // rowImplant: {
        //   handler(v) {
        //     this.autoSaveImplant(v)
        //   },
        //   deep: true
        // }
      }
  }
</script>