<template>
    <validation-observer ref="VFormTTDBedahRanap">
      <b-overlay :show="loading">
        <b-form @submit.prevent="doSubmit()">
          <div class="card">
            <div class="card-header bg-white">
              <h5 class="card-title font-weight-semibold">Transfer Intra Rumah Sakit dari Kamar Bedah</h5>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-4">
                  <b-form-group>
                    <label>Nama Pengirim</label>
                    <b-form-input v-model="dataModal.arantbr_pengirim" @input="autoSave"></b-form-input>
                    <VValidate name="Nama Pengirim" v-model="dataModal.arantbr_pengirim" :vid="'nama_ttd' + 1" rules="required" />
                  </b-form-group>
                  <div class="form-group">
                    <div class="signing-element" data-target-id="ttd1">
                      <VueSignaturePad  
                        class="sign-container"
                        width="100%"
                        height="160px"
                        ref="ttd1"
                      />
                      <div class="mt-2">
                        <button type="button" @click="undoTTD('ttd1','arantbr_pengirim_ttd')" class="btn btn-sm btn-danger btn-labeled btn-labeled-left se-cleaner">
                          <b><i class="icon-cross3"></i></b>
                          <span>Clear</span>
                        </button>
                        <button @click.prevent="output('ttd1','arantbr_pengirim_ttd')" class="btn btn-sm btn-success btn-labeled btn-labeled-left se-cleaner ml-2">
                            <b><i class="icon-checkmark2"></i></b>
                          <span>Simpan</span>
                        </button>
                      </div>
                    </div>
                  </div>

                  <b-form-group>
                    <label>Nama Penerima</label>
                    <b-form-input v-model="dataModal.arantbr_penerima" @input="autoSave"></b-form-input>
                    <VValidate name="Nama Penerima" v-model="dataModal.arantbr_penerima" :vid="'nama_ttd' + 2" rules="required" />
                  </b-form-group>

                  <div class="form-group">
                    <div class="signing-element" data-target-id="ttd2">
                      <VueSignaturePad  
                        class="sign-container"
                        width="100%"
                        height="160px"
                        ref="ttd2"
                      />
                      <div class="mt-2">
                        <button type="button" @click="undoTTD('ttd2','arantbr_penerima_ttd')" class="btn btn-sm btn-danger btn-labeled btn-labeled-left se-cleaner">
                          <b><i class="icon-cross3"></i></b>
                          <span>Clear</span>
                        </button>
                        <button @click.prevent="output('ttd2','arantbr_penerima_ttd')" class="btn btn-sm btn-success btn-labeled btn-labeled-left se-cleaner ml-2">
                            <b><i class="icon-checkmark2"></i></b>
                          <span>Simpan</span>
                        </button>
                      </div>
                    </div>
                  </div>

                </div>
                <div class="col-md-8">
                  <div class="document-framer">
                    <div class="df-content">
                      <div style="position: relative; max-width: 700px;">
                        <div style="height:100%;position: relative;font-family: serif;color: #333;line-height: 1.5;font-size: 12px;">
                          <div>
                            <table style="border-collapse: collapse;width: 100%;font-size: 13px;">
                              <tbody><tr>
                                <td>
                                  <div style="float: left;width: 10%;vertical-align: middle;box-sizing: border-box;padding-right: 8px;">
                                    <img :src="assetLocal('global_assets/images/logo-rsh-mark.png')" style="max-width: 100%;" alt="">
                                  </div>
                                  <div style="text-transform: uppercase;float: left;width: 90%;vertical-align: middle;box-sizing: border-box;padding-left: 8px;">
                                    <h1 style="margin:0;font-size: 16px;line-height: 20px;margin-bottom: 2px;">Rumah Sakit Harapan Magelang</h1>
                                    <p style="margin:0;">Jl.P Senopati No 11, Magelang 56123</p>
                                    <p style="margin:0;">Telp. (0293)-364033 s/d 364035</p>
                                    <p style="margin:0;">Fax. (0293-364037)</p>
                                  </div>
                                </td>
                                <td style="text-align: right;">
                                  <span>Magelang, {{dataModal.arano_tanggal_operasi_end | moment("DD MMMM YYYY")}}</span>
                                </td>
                              </tr>
                              <tr>
                                <th colspan="2" style="padding: 16px 8px;text-align: center;">
                                  <h2 style="font-size: 16px;text-transform: uppercase;padding-top: 16px;display: inline-block;margin-bottom: 4px;">Transfer Intra Rumah Sakit</h2>
                                  <div style="font-weight: normal;">UBS ke Ranap</div>
                                </th>
                              </tr>
                              <tr>
                                <td colspan="2" style="padding: 16px 8px;">
                                  <table style="font-size: 12px;width: 100%;line-height: 1.5;">
                                    <tbody>
                                      <tr>
                                        <th style="text-align: left;vertical-align: top;" rowspan="5">Situation</th>
                                        <td style="padding: 2px 4px;" colspan="2">
                                          <div>
                                            <div><strong>Nama</strong></div>
                                            <span>{{dataModal.ap_fullname}}</span>
                                          </div>
                                        </td>
                                        <td style="padding: 2px 4px;" colspan="2">
                                          <div>
                                            <div><strong>No. RM</strong></div>
                                            <span>{{dataModal.ap_code}}</span>
                                          </div>
                                        </td>
                                        <td style="padding: 2px 4px;" colspan="2">
                                          <div>
                                            <div><strong>NIK</strong></div>
                                            <span>{{dataModal.ap_nik}}</span>
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td style="padding: 2px 4px;" colspan="2">
                                          <div>
                                            <div><strong>Tanggal Lahir</strong></div>
                                            <span>{{dataModal.ap_dob | moment('DD MMM YYYY')}}</span>
                                          </div>
                                        </td>
                                        <td style="padding: 2px 4px;" colspan="2">
                                          <div>
                                            <div><strong>Dokter Operator</strong></div>
                                            <span>{{dataModal.dokter_operator || '-'}}</span>
                                          </div>
                                        </td>
                                        <td style="padding: 2px 4px;" colspan="2">
                                          <div>
                                            <div><strong>Dokter Anestesi</strong></div>
                                            <span>{{dataModal.dokter_anestesi || '-'}}</span>
                                          </div>
                                        </td>
                                      </tr>
  
                                      <tr>
                                        <td style="padding: 2px 4px;" colspan="2">
                                          <div>
                                            <div><strong>Diagnosa pra Operasi</strong></div>
                                            <span>{{dataModal.arano_diagnosa}}</span>
                                          </div>
                                        </td>
                                        <td style="padding: 2px 4px;" colspan="2">
                                          <div>
                                            <div><strong>Tanggal tindakan operasi</strong></div>
                                            <span>{{dataModal.arano_tanggal_operasi_start | moment("DD MMMM YYYY")}}</span>
                                          </div>
                                        </td>
                                        <td style="padding: 2px 4px;" colspan="2">
                                          <div>
                                            <div><strong>Waktu Mulai Operasi</strong></div>
                                            <span>{{dataModal.arano_tanggal_operasi_start | moment("DD MMMM YYYY")}}, {{dataModal.arano_jam_operasi_start}}</span>
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td style="padding: 2px 4px;" colspan="2">
                                          <div>
                                            <div><strong>Waktu Selesai Operasi</strong></div>
                                            <span>{{dataModal.arano_tanggal_operasi_end | moment("DD MMMM YYYY")}}, {{dataModal.arano_jam_operasi_end}}</span>
                                          </div>
                                        </td>
                                        <td style="padding: 2px 4px;" colspan="4">
                                          <div>
                                            <div><strong>Jenis Anetesi</strong></div>
                                            <span>{{dataModal.arano_jenis_new || dataModal.arano_jenis || '-'}}</span>
                                          </div>
                                        </td>
                                        </tr><tr>
                                          <td style="padding: 2px 4px;" colspan="2">
                                            <div>
                                              <div><strong>Diagnosa pasca operasi</strong></div>
                                              <span>{{dataModal.arantbr_diagnosa_pasca_operasi || '-'}}</span>
                                            </div>
                                          </td>
                                          <td style="padding: 2px 4px;" colspan="2">
                                            <div>
                                              <div><strong>Keluhan utama saat ini</strong></div>
                                              <span>{{ dataModal.arantbr_keluhan_utama }}</span>
                                            </div>
                                          </td>
                                        </tr>
                                      
                                    </tbody>
                                    <tbody>
                                      <tr>
                                        <th style="text-align: left;vertical-align: top;" rowspan="4">Background</th>
                                        <td style="padding: 2px 4px;" colspan="3">
                                          <div>
                                            <div><strong>Kelengkapan status pasien</strong></div>
                                            <p v-for="(value, index) in (dataModal.arantbr_kelengkapan_status_pasien || [])" :key="index" style="padding:0; margin:0;">{{value}}</p>
                                          </div>
                                        </td>
                                        <td style="padding: 2px 4px;" colspan="3">
                                          <div>
                                            <div><strong>Pemeriksaan jaringan</strong></div>
                                            <p v-for="(value, index) in (dataModal.arantbr_pemeriksaan_jaringan || [])" :key="index" style="padding:0; margin:0;">{{value}}</p>
                                            <div><strong>Infus yang Terpasang</strong></div>
                                            <p>{{ dataModal.arantbr_infus_yang_terpasang }}</p>
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td style="padding: 2px 4px;" colspan="3">
                                          <div>
                                            <div><strong>Alat medis yang terpasang</strong></div>
                                            <p v-if="dataModal.arantbr_ngt_no == 'Y'" style="padding:0; margin:0;">NGT No {{dataModal.arantbr_ngt_no_text}}, {{ dataModal.arantbr_ngt_no_date | moment('DD/MMM/YYYY') }} {{ dataModal.arantbr_ngt_no_jam }}</p>
                                            <p v-if="dataModal.arantbr_foley_no == 'Y'" style="padding:0; margin:0;">FOLEY No {{dataModal.arantbr_foley_no_text}}, {{ dataModal.arantbr_foley_no_date | moment('DD/MMM/YYYY') }} {{ dataModal.arantbr_foley_no_jam }}</p>
                                            <p v-if="dataModal.arantbr_ett_no == 'Y'" style="padding:0; margin:0;">ETT No {{dataModal.arantbr_ett_no_text}}, {{ dataModal.arantbr_ett_no_date | moment('DD/MMM/YYYY') }} {{ dataModal.arantbr_ett_no_jam }}</p>
                                            <p v-if="dataModal.arantbr_drain == 'Y'" style="padding:0; margin:0;">DRAIN {{dataModal.arantbr_drain_text}}, {{ dataModal.arantbr_drain_date | moment('DD/MMM/YYYY') }} {{ dataModal.arantbr_drain_jam }}</p>
                                            <p v-if="dataModal.arantbr_tampon == 'Y'" style="padding:0; margin:0;">TAMPON {{dataModal.arantbr_tampon_text}}, {{ dataModal.arantbr_tampon_date | moment('DD/MMM/YYYY') }} {{ dataModal.arantbr_tampon_jam }}</p>
                                            <p v-if="dataModal.arantbr_lainnya_no == 'Y'" style="padding:0; margin:0;">Lainnya {{dataModal.arantbr_lainnya_no_text}}, {{ dataModal.arantbr_lainnya_no_date | moment('DD/MMM/YYYY') }} {{ dataModal.arantbr_lainnya_no_jam }}</p>
                                          </div>
                                        </td>
                                        <td style="padding: 2px 4px;" colspan="3">
                                          <div>
                                            <div><strong>Terapi yang diberikan</strong></div>
                                            <p v-for="(value, index) in (dataModal.arantbr_terapi || [])" :key="index" style="padding:0; margin:0;">{{value || '-'}}</p>
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td style="padding: 2px 4px;" colspan="3">
                                          <div>
                                            <div><strong>Rencana Diit</strong></div>
                                            <p>{{ dataModal.arantbr_rencana_diit || '-' }}</p>
                                          </div>
                                        </td>
                                      
                                      </tr>
  
                                      <tr>
                                        <td style="padding: 2px 4px;" colspan="4">
                                          <div>
                                            <div><strong>Tindakan yang dilakukan</strong></div>
                                            <p v-for="(value, index) in (dataModal.arantbr_tindakan_lanjutan || [])" :key="index" style="padding:0; margin:0;">{{value || '-'}}</p>
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody>
                                    
                                    <tbody>
                                      <tr>
                                        <th style="text-align: left;vertical-align: top;" rowspan="3">Assessment</th>
                                      
                                      </tr>
  
                                      <tr>
                                        <td style="padding: 2px 4px;" colspan="2">
                                          <div>
                                            <div><strong>Tekanan Darah</strong></div>
                                            <span>{{dataModal.arantbr_tekanan_darah_min}}/{{dataModal.arantbr_tekanan_darah_max}}mg</span>
                                          </div>
                                        </td>
                                        <td style="padding: 2px 4px;" colspan="2">
                                          <div>
                                            <div><strong>Nadi</strong></div>
                                            <span>
                                              {{dataModal.arantbr_nadi}}x/menit - 
                                              <template v-if="dataModal.arantbr_label == 'R'">Regular</template>
                                              <template v-else-if="dataModal.arantbr_label == 'I'">Iregular</template>
                                            </span>
                                          </div>
                                        </td>
                                        <td style="padding: 2px 4px;" colspan="2">
                                          <div>
                                            <div><strong>Suhu</strong></div>
                                            <span>{{dataModal.arantbr_suhu}} °C</span>
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td style="padding: 2px 4px;" colspan="2">
                                          <div>
                                            <div><strong>RR</strong></div>
                                            <span>{{dataModal.arantbr_pernafasan}}x/menit</span>
                                          </div>
                                        </td>
                                        <td style="padding: 2px 4px;" colspan="2">
                                          <div>
                                            <div><strong>SpO2</strong></div>
                                            <span>{{dataModal.arantbr_spo2}}</span>
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody>
                                    <tbody>
                                      <tr>
                                        <th style="text-align: left;vertical-align: top;" rowspan="2">Recommendation</th>
                                        <td style="padding: 2px 4px;" colspan="6">
                                          <div>
                                            <div><strong>Tindakan Lanjutan</strong></div>
                                            <p v-for="(value, index) in (dataModal.arantbr_tindakan_lanjutan || [])" :key="index" style="padding:0; margin:0;">{{value || '-'}}</p>
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td style="padding: 2px 4px;" colspan="2">
                                          <div>
                                            <div><strong>Hal-hal yang perlu diperhatikan</strong></div>
                                            <p v-for="(value, index) in (dataModal.arantbr_hal_diperhatikan || [])" :key="index" style="padding:0; margin:0;">{{value || '-'}}</p>
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            </tbody></table>  
                            <table style="width: 100%;font-size: 12px;">  
                              <tbody>
                                <tr>
                                  <td style="text-align: center;">
                                    <span>Pengirim</span>
                                    <template v-if="dataModal.arantbr_pengirim_ttd">
                                      <br>
                                      <img :src="dataModal.arantbr_pengirim_ttd" alt="signature" width="200">
                                      <br/>
                                    </template>
                                    <template v-else>
                                      <br><br><br>
                                    </template>
                                    ({{ dataModal.arantbr_pengirim }})
                                  </td>
                                  <td style="text-align: center;">
                                    <span>Penerima</span>
                                    <template v-if="dataModal.arantbr_penerima_ttd">
                                      <br>
                                      <img :src="dataModal.arantbr_penerima_ttd" id="22" alt="signature" width="200">
                                      <br/>
                                    </template>
                                    <template v-else>
                                      <br><br><br>
                                    </template>
                                    ({{ dataModal.arantbr_penerima }})
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <div class="text-right">
                <button type="button" @click="$emit('closeModal')" class="btn btn-light mr-3">Close</button>
                <button type="submit" class="btn btn-primary">Submit <i class="icon-paperplane ml-2"></i></button>
              </div>
            </div>
          </div>
        </b-form>
      </b-overlay>
    </validation-observer>
</template>


<script>
const _ = global._
import Gen from '@/libs/Gen.js'
const moment = require('moment')

export default {
  props: {
    arantbr_id: Number,
    arano_id: Number
  },
  data(){
    return {
      menuPage: 'RanapTransferBedahRanap',
      mKesadaran: [
        {value: "Sadar", text: "Sadar"},
        {value: "Tidak Sadar", text: "Tidak Sadar"}
      ],
      dataModal: {},
      loading: false
    }
  },
  mounted() {
    this.$emit('resize')
    this.getDataModal() 
  },
  computed: {
    now(){
      return moment().format('DD MMM YYYY')
    }
  },
  methods: {
    assetLocal(img){
      let def = process.env.VUE_APP_MODE != 'local' ? process.env.VUE_APP_BASE_URL+"/" : "/"
      img = def+img
      return img
    },
    undoTTD(refs,field){
      this.$refs[refs].clearSignature()
      this.dataModal[field] = null
      this.autoSave()
    },
    output(refs,field) {
      try{
        let { isEmpty, data } = this.$refs[refs].saveSignature()
        if(!isEmpty){
          this.dataModal[field] = data
          this.autoSave()
        }
      }catch(err){
        console.log(err)
      }
    },

    getDataModal(params = {}, page = 1){
        this.loading = true
        let paramsQuery = {getByID: 1, arantbr_id: this.arantbr_id}
        let url = this.menuPage+ '/' + this.arano_id
        
        Gen.apiRest(
            "/get/"+url, 
            {
            params: Object.assign({page: page}, paramsQuery, params.query||{})
            }
        ).then(res=>{
            this.loading = false
            this.$set(this, 'dataModal', res.data.row)
            setTimeout(()=>{              
              this.$nextTick(() => {
                if(this.$refs['ttd1']){
                  this.$refs['ttd1'].fromDataURL(this.dataModal.arantbr_pengirim_ttd)
                } 
                if(this.$refs['ttd2']){
                  this.$refs['ttd2'].fromDataURL(this.dataModal.arantbr_penerima_ttd)
                } 
              })
            },200)
        }).catch(()=>{
            this.loading = false
        })

        
    },

    autoSave: _.debounce(function () {
        this.dataModal.type = 'auto-save'
        Gen.apiRest(
            "/do/"+this.menuPage,
            {data:this.dataModal}, 
            "POST"
        )
    },500),
    
    doSubmit(){

      if(!this.dataModal.arantbr_pengirim_ttd || !this.dataModal.arantbr_penerima_ttd){
        return this.$swal({
          icon: 'error',
          title: 'Mohon Lengkapi Tanda Tangan!'
        })
      }

      this.$refs['VFormTTDBedahRanap'].validate().then(success => {
        if (!success) {
          return this.$swal({
              icon: 'error',
              title: 'Data Tidak Lengkap, Mohon Lengkapi Data Terlebih Dahulu'
          }).then(result => {
              if (result.value) {
                  setTimeout(() => {
                      let inv = []
                      let el = document.querySelectorAll(".label_error:not([style='display: none;'])")
                      console.log(el)
                      for (let i = 0; i < (el || []).length; i++) {
                          if (el[i].style.display !== 'none') {
                          inv.push(el[i].id)
                          }
                      }
                                  
                      if (inv.length) document.getElementById(inv[0]).scrollIntoView({
                          behavior: 'smooth',
                          block: 'center'
                      })
                  }, 500)
              }
          })
        }
        if (success) {
          this.$swal({
              icon: 'warning',
              title: 'Apakah Anda Yakin akan menyimpan data ini?',
              showCancelButton: true,
              confirmButtonText: 'Ya',
              cancelButtonText: 'Tidak, kembali'
          }).then(result => {
            if (result.value) {
              this.dataModal.arantbr_template = document.querySelector('.document-framer').innerHTML
              let data = this.dataModal
              data.type = 'submit-ttd'
              
              this.loading = true
              Gen.apiRest(
              "/do/" + this.menuPage, {
                  data: data
              },
              "POST"
              ).then(res => {
                this.loading = false
                
                let resp = res.data
                resp.statusType = 200
                this.$swal({
                    title: resp.message,
                    icon: resp.status,
                    confirmButtonText: 'Ok',
                    allowOutsideClick: false,
                    allowEscapeKey: false
                }).then(result => {
                  this.$emit('successSubmit')
                })
              }).catch(err => {
                this.loading = false
                if (err) {
                  err.statusType = err.status
                  err.status = "error"
                  err.title = err.response?.data?.title
                  err.message = err.response?.data?.message
                  err.messageError = err.message
                }
                this.doSetAlertForm(err)
              })
            }
          })
        }
      })
    }
  },
}
</script>