<template>
  <div class="content-wrapper overflow-visible">
    <patient-info :patient-data="patientData" :loading="loading.patientInfo" :use-history-data="true"
      :reg-id="$route.query.regId" />

    <div class="content">
      <validation-observer ref="VForm">
        <b-form @submit.prevent="doSubmit()">
          <div class="card">
            <div class="card-header bg-white">
              <div class="row align-items-center">
                <div class="col-md-auto">
                  <div class="row align-items-center">
                    <div class="col-md-auto">
                      <h5 class="card-title font-weight-semibold">Transfer Intra Rumah Sakit Ke Kamar Bedah</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-12">
                  <div class="wrap_width_table">
                    <div class="table-responsive">
                      <table class="table  table-sm  table-bordered table-tab-breaker">
                        <tbody>
                          <tr>
                            <td class="table-warning">(S)<br>SITUATION</td>
                            <td>
                              <table class="table  table-sm  table-bordered">
                                <tbody>
                                  <tr>
                                    <td>
                                      <div class="result_tab">
                                        <h4>Dokter Operator</h4>
                                        <p>{{row.dokter_operator||"-"}}</p>
                                      </div>
                                    </td>
                                    <td>
                                      <div class="result_tab">
                                        <h4>Dokter Anestesi</h4>
                                        <p>{{row.dokter_anestesi||"-"}}</p>
                                      </div>
                                    </td>
                                    <td>
                                      <div class="result_tab">
                                        <h4>Est. Tanggal tindakan operasi</h4>
                                        <div>
                                          <span>{{row.arano_tanggal_operasi_start | moment("DD MMMM YYYY")}}
                                            {{row.arano_jam_operasi_start}}</span>
                                          <span> s/d </span>
                                          <span>{{row.arano_tanggal_operasi_end | moment("DD MMMM YYYY")}}
                                            {{row.arano_jam_operasi_end}}</span>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td colspan="3">
                                      <div class="result_tab">
                                        <h4>Diagnosa pra Operasi <span class="text-danger">*</span></h4>

                                        <b-form-textarea v-model="row.arantib_diagnosa_pra_operasi" class="form-control"
                                          rows="2"></b-form-textarea>
                                        <VValidate name="Diagnosa pra Operasi" v-model="row.arantib_diagnosa_pra_operasi"
                                          :rules="toValidate(mrValidation.arantib_diagnosa_pra_operasi)" />
                                      </div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td colspan="3">
                                      <div class="result_tab">
                                        <h4>Keluhan utama saat ini <span class="text-danger">*</span></h4>
                                        <b-form-textarea v-model="row.arantib_keluhan_utama" class="form-control"
                                          rows="2"></b-form-textarea>
                                        <VValidate name="Keluhan utama saat ini" v-model="row.arantib_keluhan_utama"
                                          :rules="toValidate(mrValidation.arantib_keluhan_utama)" />
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                          <tr>
                            <td class="table-info">(B)<br>BACKGROUND</td>
                            <td>
                              <table class="table  table-sm mb-2  table-bordered">
                                <thead>
                                  <tr>
                                    <th colspan="2">Kelengkapan status pasien</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>
                                      <b-form-group>
                                        <b-form-checkbox-group class="checkbox-block"
                                          v-model="row.arantib_kelengkapan_status_pasien" name="kelengkapan-pasien">
                                          <b-form-checkbox v-for="(v,k) in kelengkapanStatusPasien1" :key="k"
                                            :value="v.value">{{v.text}}</b-form-checkbox>
                                        </b-form-checkbox-group>
                                      </b-form-group>
                                    </td>
                                    <td>
                                      <b-form-group>
                                        <b-form-checkbox-group class="checkbox-block"
                                          v-model="row.arantib_kelengkapan_status_pasien" name="kelengkapan-pasien">
                                          <b-form-checkbox v-for="(v,k) in kelengkapanStatusPasien2" :key="k"
                                            :value="v.value">{{v.text}}</b-form-checkbox>
                                        </b-form-checkbox-group>
                                      </b-form-group>
                                    </td>
                                  </tr>

                                </tbody>
                              </table>
                              <table class="table  table-sm mb-2 table-bordered">
                                <thead>
                                  <tr>
                                    <th colspan="2">Riwayat Penyakit khronis </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>
                                      <b-form-group>
                                        <b-form-checkbox-group class="checkbox-block"
                                          v-model="row.arantib_riwayat_penyakit_kronis" name="penyakit-kronis">
                                          <b-form-checkbox v-for="(v,k) in penyakitKronis1" :key="k" :value="v.value">
                                            {{v.text}}</b-form-checkbox>
                                        </b-form-checkbox-group>
                                      </b-form-group>
                                    </td>
                                    <td>
                                      <b-form-group>
                                        <b-form-checkbox-group class="checkbox-block"
                                          v-model="row.arantib_riwayat_penyakit_kronis" name="penyakit-kronis">
                                          <b-form-checkbox v-for="(v,k) in penyakitKronis2" :key="k" :value="v.value">
                                            {{v.text}}</b-form-checkbox>
                                        </b-form-checkbox-group>
                                      </b-form-group>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <table class="table  table-sm mb-2 table-bordered">
                                <thead>
                                  <tr>
                                    <th colspan="2">Persiapan Pasien </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>
                                      <b-form-group>
                                        <b-form-checkbox-group class="checkbox-block"
                                          v-model="row.arantib_persiapan_pasien" name="persiapan-pasien">
                                          <template v-for="(v,k) in persiapanPasien1">
                                            <b-form-checkbox class="c-flex" :key="k" :value="v.value">{{v.text}}
                                              <span v-if="v.text == 'Infus, IV Cath No' && 
                                            (row.arantib_persiapan_pasien||[]).indexOf('Infus, IV Cath No') != -1">
                                                <b-form-input v-model="row.arantib_infus_cath_no" type="text"
                                                  class="form-control ml-2" />

                                                <VValidate name="Infus, IV Cath No" v-model="row.arantib_infus_cath_no"
                                                  :rules="{required: 1}" />
                                              </span>

                                              <span v-if="v.text == 'NGT No' && 
                                            (row.arantib_persiapan_pasien||[]).indexOf('NGT No') != -1">
                                                <b-form-input v-model="row.arantib_ngt_no" type="text"
                                                  class="form-control ml-2" />

                                                <VValidate name="NGT No" v-model="row.arantib_ngt_no"
                                                  :rules="{required: 1}" />
                                              </span>
                                            </b-form-checkbox>

                                          </template>

                                        </b-form-checkbox-group>
                                      </b-form-group>
                                    </td>
                                    <td>
                                      <b-form-group>
                                        <b-form-checkbox-group class="checkbox-block"
                                          v-model="row.arantib_persiapan_pasien" name="persiapan-pasien">
                                          <div v-for="(v,k) in persiapanPasien2" :key="k">
                                            <b-form-checkbox :value="v.value">{{v.text}}
                                              <div v-if="v.text == 'Foley cath' && 
                                            (row.arantib_persiapan_pasien||[]).indexOf('Foley cath') != -1">
                                                <b-form-input v-model="row.arantib_foley_cath" type="text"
                                                  class="form-control" />
                                                <VValidate name="Foley cath" v-model="row.arantib_foley_cath"
                                                  :rules="{required: 1}" />
                                              </div>
                                            </b-form-checkbox>
                                            <div class="mb-2" v-if="v.text == 'Puasa jam' && 
                                          (row.arantib_persiapan_pasien||[]).indexOf('Puasa jam') != -1">
                                              <vue-timepicker manual-input format="HH:mm" input-class="form-control"
                                                v-model="row.arantib_jam_puasa">
                                              </vue-timepicker>
                                              <VValidate name="Puasa jam" v-model="row.arantib_jam_puasa"
                                                :rules="{required: 1}" />
                                            </div>
                                          </div>
                                        </b-form-checkbox-group>
                                      </b-form-group>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <table class="table table-input  table-sm mb-2 table-bordered">
                                <thead>
                                  <tr>
                                    <th colspan="2">Obat-obatan yang diberikan </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr v-for="(v,k) in (row.arantib_premedikasi||[])" :key="k+'premedikasi'">
                                    <td class="input-cell">
                                      <b-form-input v-model="row.arantib_premedikasi[k]" type="text"
                                        placeholder="Premedikasi" class="form-control form-control-sm" />
                                      <VValidate :name="'Premedikasi #'+(k+1)" v-model="row.arantib_premedikasi[k]"
                                        :rules="{required: 1}" />
                                    </td>

                                    <td>
                                      <a href="javascript:;" @click="(row.arantib_premedikasi||[]).splice(k,1)"
                                        class="btn btn-sm alpha-primary border-primary text-primary-800 btn-icon rounded-round"
                                        v-b-tooltip.hover title="Delete"><i class="icon-trash"></i></a>
                                    </td>
                                  </tr>
                                </tbody>
                                <tfoot>
                                  <tr>
                                    <td class="text-center" colspan="3">
                                      <a href="javascript:;" @click="row.arantib_premedikasi.push('')"
                                        class="btn btn-outline-info alpha-info text-blue-800 btn-sm">
                                        <i class="mr-2 icon-plus2"></i>
                                        <span>Tambah</span>
                                      </a>
                                    </td>
                                  </tr>
                                </tfoot>
                              </table>
                              <div class="head_panel_red mt-2">
                                <div class="d-flex justify-content-between align-items-center">
                                  <h3>Antibiotik Profilaksis </h3>
                                </div>
                              </div>

                              <table class="table table-striped table-sm table-hover table-bordered">
                                <thead>
                                  <tr>
                                    <th width="48">Aksi</th>
                                    <th width="160">Jenis</th>
                                    <th width="320">Nama</th>
                                    <th width="150">Jumlah</th>
                                    <th width="210">Frekuensi</th>
                                    <th>Keterangan</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr v-for="(v,k) in resepDokter" :key="'resepDokter'+k">
                                    <td>
                                      <a href="javascript:;" @click="removeObat(v,k)"
                                        class="btn btn-sm alpha-danger border-danger text-danger-800 btn-icon rounded-round"><i
                                          class="icon-trash"></i></a>
                                    </td>
                                    <td>
                                      <v-select placeholder="Pilih Item" v-model="resepDokter[k].aranapro_jenis"
                                        :options="Config.mr.configResepRanap" label="text"
                                        @input="changeJenis($event,k)" :reduce="v=>v.value">
                                      </v-select>
                                      <VValidate :name="'Jenis '+(k+1)" message="Jenis Harus Diisi"
                                        v-model="resepDokter[k].aranapro_jenis" :rules="{required : 1}" />
                                    </td>
                                    <td>
                                      <div class="d-flex">
                                        <template v-if="resepDokter[k]['aranapro_jenis'] == 1">
                                          <v-select style="flex: 1;" class="med-selection select-paging"
                                            placeholder="Pilih Item"
                                            :class="obatLen <= 5 ? 'sp-col diagnosa-section': 'sp-col'" @open="countLen"
                                            @search="filterObat($event,k);countLen();"
                                            v-model="resepDokter[k].aranapro_item_id" :options="mObatPreferensi"
                                            label="text" @input="selectJumlahSatuan($event,k)" :clearable="true"
                                            :reduce="v=>v.value" :selectable="v=>v.value !== 'pd' && v.value !== 'ol'">
                                            <template slot="selected-option">
                                              <span
                                                :class="isAlergiLabel(v.aranapro_nama)?'text-warning':''">{{ v.aranapro_nama }}</span>
                                            </template>
                                            <template slot="option" slot-scope="option">
                                              <span :class="isAlergiLabel(option.text)?'text-warning':''"
                                                v-if="option.preferensi"><b>{{ option.text }}</b></span>
                                              <span :class="isAlergiLabel(option.text)?'text-warning':''"
                                                v-else>{{ option.text }}</span>
                                            </template>
                                            <template #list-footer>
                                              <li class="list-paging">
                                                <div class="d-flex align-items-center justify-content-between">
                                                  <b-button @click="changePageObat(v.currentPage,k,'min','resepDokter')"
                                                    size="sm" variant="transparent" class="btn-icon bg-blue-300">
                                                    <i class="icon-arrow-left22 text-white"></i></b-button>
                                                  <small>Halaman {{v.currentPage}} dari
                                                    {{ceilData(v.totalRows/10)}}</small>
                                                  <b-button
                                                    @click="changePageObat(v.currentPage,k,'plus','resepDokter')"
                                                    size="sm" variant="transparent" class="btn-icon bg-blue-300">
                                                    <i class="icon-arrow-right22 text-white"></i></b-button>
                                                </div>
                                              </li>
                                            </template>
                                          </v-select>
                                          <br />
                                        </template>

                                        <template v-else-if="resepDokter[k]['aranapro_jenis'] == 2">
                                          <v-select style="flex: 1;" class="med-selection select-paging select-custom-height"
                                            placeholder="Pilih Item" v-model="resepDokter[k].aranapro_item_id"
                                            :options="mAlatKesehatan" label="text"
                                            @input="selectJumlahSatuanAlkes($event,k)" :clearable="true"
                                            :reduce="v=>v.value">
                                            <template slot="selected-option" slot-scope="option">
                                              <span>{{ option.text }}</span>
                                            </template>
                                            <template slot="option" slot-scope="option">
                                              <span>{{ option.text }}</span>
                                            </template>
                                          </v-select>
                                        </template>

                                        <template v-else-if="resepDokter[k]['aranapro_jenis'] == 3">
                                          <v-select style="flex: 1;" class="med-selection select-paging select-custom-height"
                                            placeholder="Pilih Item" v-model="resepDokter[k].aranapro_item_id"
                                            :options="mRacikan" label="text"
                                            @input="selectJumlahSatuanRacikan($event,k)" :clearable="true"
                                            :reduce="v=>v.value">
                                            <template slot="selected-option" slot-scope="option">
                                              <span>{{ option.text }}</span>
                                            </template>
                                            <template slot="option" slot-scope="option">
                                              <span>{{ option.text }}</span>
                                            </template>
                                          </v-select>
                                        </template>
                                        
                                        <template v-else-if="resepDokter[k]['aranapro_jenis'] == 5">
                                          <v-select style="flex: 1;" class="med-selection select-paging select-custom-height"
                                            placeholder="Pilih Item" v-model="resepDokter[k].aranapro_item_id"
                                            :options="mAlatInfus" label="text"
                                            @input="selectJumlahSatuanInfus($event,k)" :clearable="true"
                                            :reduce="v=>v.value">
                                            <template slot="selected-option" slot-scope="option">
                                              <span>{{ option.text }}</span>
                                            </template>
                                            <template slot="option" slot-scope="option">
                                              <span>{{ option.text }}</span>
                                            </template>
                                          </v-select>
                                        </template>

                                        <a href="javascript:;" v-if="resepDokter[k]['aranapro_jenis'] !== 3"
                                          @click="resepDokter[k]['aranapro_item_id'] = 99999; resepDokter[k]['aranapro_nama'] = 'Lainnya'"
                                          v-b-tooltip.hover="'Pilih Lainnya'"
                                          class="btn btn-sm btn-icon alpha-info border-info btn-plus-other"><i
                                            class="icon-plus-circle2"></i></a>
                                      </div>
                                      <VValidate :name="'Nama Item '+(k+1)" message="Nama Item Harus Diisi"
                                        v-model="resepDokter[k]['aranapro_item_id']" :rules="{required : 1}" />

                                      <div v-if="resepDokter[k]['aranapro_item_id'] == 99999">
                                        <b-form-input type="text" v-model="resepDokter[k]['aranapro_item_lainnya']"
                                          class="form-control" id="itemCount" />
                                        <VValidate :name="'Item Lainnya '+(k+1)" message="Nama Item Lainnya Harus Diisi"
                                          v-model="resepDokter[k]['aranapro_item_lainnya']" :rules="{required : 1}" />
                                      </div>

                                      <a v-if="resepDokter[k].aranapro_jenis == 3 && resepDokter[k]['aranapro_item_id']"
                                        href="javascript:;" @click="toRacikan(v)" data-popup="tooltip" title=""
                                        data-toggle="modal" data-target="#modalRacikanDetail"
                                        class="font-weight-semibold border-bottom"
                                        data-original-title="Lihat Detail Resep">Lihat Detail Racikan</a>

                                      <small class="text-warning"
                                        v-if="isAlergiLabel(resepDokter[k].aranapro_nama) ">Obat ini termasuk dalam
                                        kategori alergi</small>
                                    </td>
                                    <td>
                                      <div class="input-group">
                                        <b-form-input type="text" v-model="resepDokter[k]['aranapro_jumlah']"
                                          :formatter="number" class="form-control" id="itemCount" />
                                        <div class="input-group-append">
                                          <span class="input-group-text">{{resepDokter[k]['aranapro_satuan']}}</span>
                                        </div>
                                      </div>

                                      <VValidate :name="'Jumlah '+(k+1)" message="Jumlah Harus Diisi"
                                        v-model="resepDokter[k]['aranapro_jumlah']" :rules="{required : 1}" />
                                    </td>
                                    <td>
                                      <span class="d-flex">
                                        <v-select style="flex: 1;" class="med-selection" placeholder="Pilih Frekuensi"
                                          v-model="resepDokter[k].aranapro_frekuensi" :options="mDosis" label="text"
                                          :clearable="true" :reduce="v=>v.value">
                                        </v-select>
                                        <a href="javascript:;" @click="resepDokter[k]['aranapro_frekuensi'] = 99999"
                                          v-b-tooltip.hover="'Pilih Lainnya'"
                                          class="btn btn-sm btn-icon alpha-info border-info btn-plus-other"><i
                                            class="icon-plus-circle2"></i></a>
                                      </span>
                                      <VValidate :name="'Frekuensi '+(k+1)" message="Frekuensi Harus Diisi"
                                        v-model="resepDokter[k]['aranapro_frekuensi']"
                                        v-if="resepDokter[k].aranapro_jenis != 2" :rules="{required : 1}" />

                                      <div v-if="resepDokter[k]['aranapro_frekuensi'] == 99999">
                                        <b-form-input type="text" v-model="resepDokter[k]['aranapro_frekuensi_lainnya']"
                                          class="form-control" id="itemCount" />
                                        <VValidate :name="'Frekuensi Lainnya '+(k+1)"
                                          message="Frekuensi Lainnya Harus Diisi"
                                          v-if="resepDokter[k].aranapro_jenis != 2"
                                          v-model="resepDokter[k]['aranapro_frekuensi_lainnya']"
                                          :rules="{required : 1}" />
                                      </div>
                                    </td>
                                    <td>
                                      <b-textarea v-model="resepDokter[k].aranapro_keterangan" name="itemNotes"
                                        id="itemNotes" rows="2" placeholder="cth. obat dihabiskan" class="form-control">
                                      </b-textarea>
                                    </td>
                                  </tr>
                                </tbody>
                                <tfoot>
                                  <tr>
                                    <td class="text-center" colspan="99">
                                      <a href="javascript:;" @click="openObat()" class="btn alpha-blue"
                                        data-toggle="modal" data-target="#modalResep">
                                        <i class="icon-plus2 mr-1 align-middle"></i>
                                        <span class="align-middle">Tambah Resep</span>
                                      </a>
                                    </td>
                                  </tr>
                                </tfoot>
                              </table>

                              <div class="head_panel_red">
                                <div class="d-flex justify-content-between align-items-center">
                                  <h3>ALERGI </h3>
                                  <div>
                                    <b-form-radio-group @input="changeAlergi($event)" :options="Config.mr.yesNoOpt"
                                      v-model="row.arantib_has_alergi" />

                                    <VValidate name="Alergi" v-model="row.arantib_has_alergi"
                                      :rules="toValidate(mrValidation.arantib_has_alergi)" />
                                  </div>
                                </div>
                              </div>

                              <template v-if="row.arantib_has_alergi == 'Y'">
                                <table class="table table-sm table-bordered">
                                  <thead>
                                    <tr>
                                      <th width="33%">Jenis</th>
                                      <th>Informasi Alergi</th>
                                      <th width="64"></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr v-for="(v,k) in (row.arantib_alergi||[])" :key="k+'aler'">
                                      <td>
                                        <v-select placeholder="Pilih Jenis" v-model="row.arantib_alergi[k]['jenis']"
                                          :options="Config.mr.jenisAlergi" label="text" :clearable="true"
                                          :reduce="v=>v.value"></v-select>
                                        <VValidate :name="'Obat '+(k+1)" v-model="row.arantib_alergi[k]['jenis']"
                                          :rules="{required:1}" />
                                      </td>
                                      <td>
                                        <vue-typeahead-bootstrap v-model="row.arantib_alergi[k]['name']" :data="mAlergi"
                                          @input="searchAlergi(row.arantib_alergi[k]['name'])"
                                          placeholder="Pilih Alergi" />

                                        <VValidate :name="'Obat '+(k+1)" v-model="row.arantib_alergi[k]['name']"
                                          :rules="{required:1}" />
                                      </td>
                                      <td>
                                        <a href="javascript:;" @click="removeAlergi(k)" data-popup="tooltip"
                                          title="Hapus"
                                          class="btn btn-sm btn-icon border-danger rounded-round text-danger-800 alpha-danger"><i
                                            class="icon-trash"></i></a>
                                      </td>
                                    </tr>
                                  </tbody>
                                  <tfoot>
                                    <tr>
                                      <td colspan="3" class="text-center">
                                        <a href="javascript:;" @click="addAlergi()"
                                          class="btn btn-sm alpha-info border-info"><i
                                            class="icon-plus2 mr-1"></i>Tambah</a>
                                      </td>
                                    </tr>
                                  </tfoot>
                                </table>
                                <span class="text-warning d-none" id="alergi-typing">User Lain Sedang Mengisi ...</span>
                              </template>
                              <template v-else>
                                <table class="table table-sm table-bordered">
                                  <thead>
                                    <tr>
                                      <th width="33%">Jenis</th>
                                      <th>Informasi Alergi</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td>
                                        <v-select placeholder="Pilih Jenis" disabled :options="Config.mr.jenisAlergi"
                                          label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                                      </td>
                                      <td>
                                        <input disabled type="text" class="form-control" placeholder="cth. amoxicilin">
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </template>
                              <div class="card mb-2 mt-2">
                                <div class="card-header bg_head_panel">
                                  <h6 class="card-title font-weight-semibold">Tanda Tanda Vital</h6>
                                </div>
                                <div class="card-body p-3">
                                  <div class="row">
                                    <div class="col-md-5 col-lg-4">
                                      <div class="form-group">
                                        <label>Tekanan Darah </label>
                                        <div class="input-group">
                                          <b-form-input :formatter="number" v-model="row.arantib_ttv_tekanan_darah_min"
                                            type="text" name="name" class="form-control" placeholder="Systole" />

                                          <div class="input-group-append input-group-prepend"><span
                                              class="input-group-text">/</span></div>

                                          <b-form-input :formatter="number" placeholder="Diastole"
                                            v-model="row.arantib_ttv_tekanan_darah_max" type="text"
                                            class="form-control" />
                                          <div class="input-group-append"><span class="input-group-text">mmHG</span>
                                          </div>
                                        </div>

                                        <VValidate name="Tekanan Darah Min" v-model="row.arantib_ttv_tekanan_darah_min"
                                          :rules="toValidate(mrValidation.arantib_ttv_tekanan_darah_min)" />
                                        <VValidate name="Tekanan Darah Max" v-model="row.arantib_ttv_tekanan_darah_max"
                                          :rules="toValidate(mrValidation.arantib_ttv_tekanan_darah_max)" />
                                      </div>
                                    </div>

                                    <div class="col-md-6 col-lg-5">
                                      <div class="form-group">
                                        <label>Nadi</label>
                                        <div class="form-row">
                                          <div class="col-md-12">
                                            <div class="input-group">
                                              <b-form-input :formatter="number" v-model="row.arantib_ttv_nadi"
                                                type="text" class="form-control" />
                                              <div class="input-group-append"><span
                                                  class="input-group-text">x/mnt</span></div>
                                              <div class="input-group-append">
                                                <div style="width: 140px;">
                                                  <v-select placeholder="Pilih Label" v-model="row.arantib_ttv_label"
                                                    :options="Config.mr.StatusRegular" label="text" :clearable="true"
                                                    :reduce="v=>v.value"></v-select>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                        <VValidate name="Nadi" v-model="row.arantib_ttv_nadi"
                                          :rules="toValidate(mrValidation.arantib_ttv_nadi)" />
                                        <VValidate name="Label" v-model="row.arantib_ttv_label"
                                          :rules="toValidate(mrValidation.arantib_ttv_label)" />
                                      </div>
                                    </div>
                                    <div class="col-md-4 col-lg-3">
                                      <div class="form-row">
                                        <label>GDA</label>
                                        <div class="col-md-12">
                                          <div class="input-group">
                                            <b-form-input :formatter="alphanum" v-model="row.arantib_ttv_gula_darah"
                                              type="text" class="form-control" />
                                            <div class="input-group-append"><span class="input-group-text">mg/dL</span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <VValidate name="Gula Darah" v-model="row.arantib_ttv_gula_darah"
                                        :rules="toValidate(mrValidation.arantib_ttv_gula_darah)" />
                                    </div>

                                    <div class="col-md-4 col-lg-3">
                                      <div class="form-group">
                                        <label>Pernafasan<span class="text-danger">*</span></label>
                                        <div class="input-group">
                                          <b-form-input :formatter="number" v-model="row.arantib_ttv_pernafasan"
                                            type="text" class="form-control" />
                                          <div class="input-group-append"><span class="input-group-text">x/mnt</span>
                                          </div>
                                        </div>

                                        <VValidate name="Pernafasan" v-model="row.arantib_ttv_pernafasan"
                                          :rules="toValidate(mrValidation.arantib_ttv_pernafasan)" />
                                      </div>
                                    </div>

                                    <div class="col-md-4 col-lg-3">
                                      <div class="form-group">
                                        <label>SPO2</label>
                                        <div class="form-row">
                                          <div class="col-md-12">
                                            <div class="input-group">
                                              <b-form-input :formatter="number" v-model="row.arantib_ttv_spo2"
                                                type="text" class="form-control" />
                                              <div class="input-group-append"><span class="input-group-text">%</span>
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                        <VValidate name="SPO2" v-model="row.arantib_ttv_spo2"
                                          :rules="toValidate(mrValidation.arantib_ttv_spo2)" />
                                      </div>
                                    </div>

                                    <div class="col-md-3 col-lg-2">
                                      <div class="form-group">
                                        <label>Suhu<span class="text-danger">*</span></label>
                                        <div class="form-row">

                                          <div class="col-md-12">
                                            <div class="input-group">
                                              <b-form-input :formatter="number" v-model="row.arantib_ttv_suhu"
                                                type="text" class="form-control" />
                                              <div class="input-group-append"><span class="input-group-text">C</span>
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                        <VValidate name="Suhu" v-model="row.arantib_ttv_suhu"
                                          :rules="toValidate(mrValidation.arantib_ttv_suhu)" />
                                      </div>
                                    </div>

                                    <div class="col-md-3 col-lg-2">
                                      <div class="form-group">
                                        <label>Berat Badan</label>
                                        <div class="form-row">

                                          <div class="col-md-12">
                                            <div class="input-group">
                                              <b-form-input :formatter="number" v-model="row.arantib_ttv_weight"
                                                type="text" class="form-control" />
                                              <div class="input-group-append"><span class="input-group-text">kg</span>
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                        <VValidate name="Berat Badan" v-model="row.arantib_ttv_weight"
                                          :rules="toValidate(mrValidation.arantib_ttv_weight)" />
                                      </div>
                                    </div>

                                    <div class="col-md-3 col-lg-2">
                                      <div class="form-group">
                                        <label>Tinggi Badan</label>
                                        <div class="form-row">

                                          <div class="col-md-12">
                                            <div class="input-group">
                                              <b-form-input :formatter="number" v-model="row.arantib_ttv_height"
                                                type="text" class="form-control" />
                                              <div class="input-group-append"><span class="input-group-text">cm</span>
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                        <VValidate name="Tinggi Badan" v-model="row.arantib_ttv_height"
                                          :rules="toValidate(mrValidation.arantib_ttv_height)" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <table class="table table-input  table-sm mb-2 table-bordered">
                                <thead>
                                  <tr>
                                    <th colspan="2">Tindakan Yang Akan Dilakukan </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr v-for="(v,k) in (row.arantib_tindakan_yang_dilakukan||[])"
                                    :key="k+'Tindakan Yang Akan Dilakukan'">
                                    <td class="input-cell">
                                      <b-form-input v-model="row.arantib_tindakan_yang_dilakukan[k]" type="text"
                                        placeholder="Tindakan Yang Akan Dilakukan" class="form-control form-control-sm" />
                                      <VValidate :name="'Tindakan #'+(k+1)"
                                        v-model="row.arantib_tindakan_yang_dilakukan[k]" :rules="{required: 1}" />
                                    </td>

                                    <td>
                                      <a href="javascript:;"
                                        @click="(row.arantib_tindakan_yang_dilakukan||[]).splice(k,1)"
                                        class="btn btn-sm alpha-primary border-primary text-primary-800 btn-icon rounded-round"
                                        v-b-tooltip.hover title="Delete"><i class="icon-trash"></i></a>
                                    </td>
                                  </tr>
                                </tbody>
                                <tfoot>
                                  <tr>
                                    <td class="text-center" colspan="3">
                                      <a href="javascript:;" @click="row.arantib_tindakan_yang_dilakukan.push('')"
                                        class="btn btn-outline-info alpha-info text-blue-800 btn-sm">
                                        <i class="mr-2 icon-plus2"></i>
                                        <span>Tambah</span>
                                      </a>
                                    </td>
                                  </tr>
                                </tfoot>
                              </table>

                              <table class="table   table-sm mb-2 table-bordered">
                                <thead>
                                  <tr>
                                    <th>Alat medis yang terpasang </th>
                                    <th>Tanggal Pasang </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>
                                      <div class="custom-control mt-1 custom-checkbox custom-control-inline p-0">
                                        <b-form-checkbox value="Y" unchecked-value="N" v-model="row.arantib_medis_infus"
                                          :name="'arantib_medis_infus'" class="form-check-input-styled" />
                                        INFUS, IV cath no
                                        <div class="ml-2" v-if="row.arantib_medis_infus == 'Y'">
                                          <b-form-input v-model="row.arantib_medis_infus_text" type="text"
                                            class="form-control mt-1" />
                                          <VValidate :name="'INFUS, IV cath no'" v-model="row.arantib_medis_infus_text"
                                            :rules="{required: 1}" />
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <div class="input-group">
                                        <div class="input-group-prepend">
                                          <span class="input-group-text"><i class="icon-calendar22"></i></span>
                                        </div>
                                        <datepicker :clear-button="true" input-class="form-control transparent"
                                          calendar-class="my-datepicker" v-model="row.arantib_medis_infus_date">
                                        </datepicker>

                                      </div>

                                      <VValidate v-if="row.arantib_medis_infus == 'Y'"
                                        :name="'Tanggal Pasang INFUS, IV cath no'"
                                        v-model="row.arantib_medis_infus_date" :rules="{required: 1}" />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <div class="custom-control mt-1 custom-checkbox custom-control-inline p-0">
                                        <b-form-checkbox value="Y" unchecked-value="N" v-model="row.arantib_medis_ngt"
                                          :name="'arantib_medis_ngt'" class="form-check-input-styled" />
                                        NGT no
                                        <div class="ml-2" v-if="row.arantib_medis_ngt == 'Y'">
                                          <b-form-input v-model="row.arantib_medis_ngt_text" type="text"
                                            class="form-control mt-1" />
                                          <VValidate :name="'NGT no'" v-model="row.arantib_medis_ngt_text"
                                            :rules="{required: 1}" />
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <div class="input-group">
                                        <div class="input-group-prepend">
                                          <span class="input-group-text"><i class="icon-calendar22"></i></span>
                                        </div>
                                        <datepicker :clear-button="true" input-class="form-control transparent"
                                          class="my-datepicker" calendar-class="my-datepicker_calendar"
                                          v-model="row.arantib_medis_ngt_date">
                                        </datepicker>

                                      </div>

                                      <VValidate v-if="row.arantib_medis_ngt == 'Y'" :name="'Tanggal Pasang NGT no'"
                                        v-model="row.arantib_medis_ngt_date" :rules="{required: 1}" />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <div class="custom-control mt-1 custom-checkbox custom-control-inline p-0">
                                        <b-form-checkbox value="Y" unchecked-value="N" v-model="row.arantib_medis_foley"
                                          :name="'arantib_medis_foley'" class="form-check-input-styled" />
                                        Foley cath no
                                        <div class="ml-2" v-if="row.arantib_medis_foley == 'Y'">
                                          <b-form-input v-model="row.arantib_medis_foley_text" type="text"
                                            class="form-control mt-1" />
                                          <VValidate :name="'Foley cath no'" v-model="row.arantib_medis_foley_text"
                                            :rules="{required: 1}" />
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <div class="input-group">
                                        <div class="input-group-prepend">
                                          <span class="input-group-text"><i class="icon-calendar22"></i></span>
                                        </div>
                                        <datepicker :clear-button="true" input-class="form-control transparent"
                                          class="my-datepicker" calendar-class="my-datepicker_calendar"
                                          v-model="row.arantib_medis_foley_date">
                                        </datepicker>

                                      </div>

                                      <VValidate v-if="row.arantib_medis_foley == 'Y'"
                                        :name="'Tanggal Pasang Foley cath no'" v-model="row.arantib_medis_foley_date"
                                        :rules="{required: 1}" />
                                    </td>
                                  </tr>

                                  <tr>
                                    <td>
                                      <div class="custom-control mt-1 custom-checkbox custom-control-inline p-0">
                                        <b-form-checkbox value="Y" unchecked-value="N" v-model="row.arantib_medis_lain"
                                          :name="'arantib_medis_lain'" class="form-check-input-styled" />
                                        Lain-lain
                                        <div class="ml-2" v-if="row.arantib_medis_lain == 'Y'">
                                          <b-form-input v-model="row.arantib_medis_lain_text" type="text"
                                            class="form-control mt-1" />
                                          <VValidate :name="'Lain-lain'" v-model="row.arantib_medis_lain_text"
                                            :rules="{required: 1}" />
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <div class="input-group">
                                        <div class="input-group-prepend">
                                          <span class="input-group-text"><i class="icon-calendar22"></i></span>
                                        </div>
                                        <datepicker :clear-button="true" input-class="form-control transparent"
                                          class="my-datepicker" calendar-class="my-datepicker_calendar"
                                          v-model="row.arantib_medis_lain_date">
                                        </datepicker>

                                      </div>

                                      <VValidate v-if="row.arantib_medis_lain == 'Y'" :name="'Tanggal Pasang Lain-lain'"
                                        v-model="row.arantib_medis_lain_date" :rules="{required: 1}" />
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                          <tr>
                            <td class="table-success">(A)<br>ASSESSMENT</td>
                            <td>
                              <table class="table table-input  table-sm mb-2 table-bordered">
                                <thead>
                                  <tr>
                                    <th colspan="2">Assessment </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr v-for="(v,k) in (row.arantib_asesmen||[])" :key="k+'Assessment'">
                                    <td class="input-cell">
                                      <b-form-input v-model="row.arantib_asesmen[k]" type="text"
                                        placeholder="Assessment" class="form-control form-control-sm" />
                                      <VValidate :name="'Assesment #'+(k+1)" v-model="row.arantib_asesmen[k]"
                                        :rules="{required: 1}" />
                                    </td>

                                    <td>
                                      <a href="javascript:;" @click="(row.arantib_asesmen||[]).splice(k,1)"
                                        class="btn btn-sm alpha-primary border-primary text-primary-800 btn-icon rounded-round"
                                        v-b-tooltip.hover title="Delete"><i class="icon-trash"></i></a>
                                    </td>
                                  </tr>
                                </tbody>
                                <tfoot>
                                  <tr>
                                    <td class="text-center" colspan="3">
                                      <a href="javascript:;" @click="row.arantib_asesmen.push('')"
                                        class="btn btn-outline-info alpha-info text-blue-800 btn-sm">
                                        <i class="mr-2 icon-plus2"></i>
                                        <span>Tambah</span>
                                      </a>
                                    </td>
                                  </tr>
                                </tfoot>
                              </table>
                            </td>
                          </tr>
                          <tr>
                            <td class="table-danger">(R)<br>RECOMMENDATION</td>
                            <td>
                              <table class="table table-input  table-sm mb-2 table-bordered">
                                <thead>
                                  <tr>
                                    <th colspan="2">Tindakan lanjutan </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr v-for="(v,k) in (row.arantib_tindakan_lanjutan||[])" :key="k+'Tindakan lanjutan'">
                                    <td class="input-cell">
                                      <b-form-input v-model="row.arantib_tindakan_lanjutan[k]" type="text"
                                        placeholder="Tindakan lanjutan" class="form-control form-control-sm" />
                                      <VValidate :name="'Assesment #'+(k+1)" v-model="row.arantib_tindakan_lanjutan[k]"
                                        :rules="{required: 1}" />
                                    </td>

                                    <td>
                                      <a href="javascript:;" @click="(row.arantib_tindakan_lanjutan||[]).splice(k,1)"
                                        class="btn btn-sm alpha-primary border-primary text-primary-800 btn-icon rounded-round"
                                        v-b-tooltip.hover title="Delete"><i class="icon-trash"></i></a>
                                    </td>
                                  </tr>
                                </tbody>
                                <tfoot>
                                  <tr>
                                    <td class="text-center" colspan="3">
                                      <a href="javascript:;" @click="row.arantib_tindakan_lanjutan.push('')"
                                        class="btn btn-outline-info alpha-info text-blue-800 btn-sm">
                                        <i class="mr-2 icon-plus2"></i>
                                        <span>Tambah</span>
                                      </a>
                                    </td>
                                  </tr>
                                </tfoot>
                              </table>

                              <table class="table table-input  table-sm mb-2 table-bordered">
                                <thead>
                                  <tr>
                                    <th colspan="2">Hal-hal yang perlu diperhatikan </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr v-for="(v,k) in (row.arantib_hal_diperhatikan||[])"
                                    :key="k+'Hal-hal yang perlu diperhatikan'">
                                    <td class="input-cell">
                                      <b-form-input v-model="row.arantib_hal_diperhatikan[k]" type="text"
                                        placeholder="Hal-hal yang perlu diperhatikan"
                                        class="form-control form-control-sm" />
                                      <VValidate :name="'Assesment #'+(k+1)" v-model="row.arantib_hal_diperhatikan[k]"
                                        :rules="{required: 1}" />
                                    </td>

                                    <td>
                                      <a href="javascript:;" @click="(row.arantib_hal_diperhatikan||[]).splice(k,1)"
                                        class="btn btn-sm alpha-primary border-primary text-primary-800 btn-icon rounded-round"
                                        v-b-tooltip.hover title="Delete"><i class="icon-trash"></i></a>
                                    </td>
                                  </tr>
                                </tbody>
                                <tfoot>
                                  <tr>
                                    <td class="text-center" colspan="3">
                                      <a href="javascript:;" @click="row.arantib_hal_diperhatikan.push('')"
                                        class="btn btn-outline-info alpha-info text-blue-800 btn-sm">
                                        <i class="mr-2 icon-plus2"></i>
                                        <span>Tambah</span>
                                      </a>
                                    </td>
                                  </tr>
                                </tfoot>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>

                      <!--
                    <table class="table table-input  table-sm  table-bordered">
                      <tr>
                        <td colspan="2">
                          <table class="table table-input  table-sm  table-bordered">
                            <tbody>
                              <tr>
                                <td style="width:33%">
                                  <div>
                                    <label for="">Keluarga <strong class="text-danger">*</strong></label>
                                    <b-form-input v-model="row.arantib_keluarga_name" type="text" class="form-control"/>
                                    <VValidate 
                                        name="Keluarga" 
                                        v-model="row.arantib_keluarga_name" 
                                        :rules="{required:1, min:3, max:128}"
                                    />

                                    <div class="signing-element mt-1">
                                      <VueSignaturePad
                                          class="sign-container"
                                          width="100%"
                                          height="160px"
                                          ref="ttdkeluarga"
                                      />
                                      <div class="mt-2">
                                      <button type="button" @click="undoTTD('ttdkeluarga','arantib_keluarga')" class="btn btn-sm btn-danger btn-labeled btn-labeled-left se-cleaner">
                                          <b><i class="icon-cross3"></i></b>
                                          <span>Clear</span>
                                      </button>
                                      
                                      <button type="button" @click="output('ttdkeluarga','arantib_keluarga')" class="btn btn-sm btn-success btn-labeled btn-labeled-left se-cleaner ml-2">
                                          <b><i class="icon-checkmark2"></i></b>
                                      <span>Simpan</span>
                                      </button>
                                      </div>
                                      
                                      <VValidate name="Tanda Tangan Keluarga" v-model="row.arantib_keluarga"
                                      :rules="{required:1}" />
                                    </div>
                                  </div>
                                </td>
                                <td style="width:33%">
                                  <div>
                                    <label for="">Staf Pengirim <strong class="text-danger">*</strong></label>
                                    <b-form-input v-model="row.arantib_staff_pengirim_name" type="text" class="form-control"/>
                                    <VValidate 
                                        name="Pengirim" 
                                        v-model="row.arantib_staff_pengirim_name" 
                                        :rules="{required:1, min:3, max:128}"
                                    />

                                    <div class="signing-element mt-1">
                                      <VueSignaturePad
                                          class="sign-container"
                                          width="100%"
                                          height="160px"
                                          ref="ttdStaff"
                                      />
                                      <div class="mt-2">
                                      <button type="button" @click="undoTTD('ttdStaff','arantib_staff_pengirim')" class="btn btn-sm btn-danger btn-labeled btn-labeled-left se-cleaner">
                                          <b><i class="icon-cross3"></i></b>
                                          <span>Clear</span>
                                      </button>
                                      
                                      <button type="button" @click="output('ttdStaff','arantib_staff_pengirim')" class="btn btn-sm btn-success btn-labeled btn-labeled-left se-cleaner ml-2">
                                          <b><i class="icon-checkmark2"></i></b>
                                      <span>Simpan</span>
                                      </button>
                                      </div>
                                      
                                      <VValidate name="Tanda Tangan Staf Pengirim" v-model="row.arantib_staff_pengirim"
                                      :rules="{required:1}" />
                                    </div>

                                  </div>
                                </td>
                                <td style="width:33%">
                                  <div>
                                    <label for="">Staf Penerima <strong class="text-danger">*</strong></label>
                                    <b-form-input v-model="row.arantib_staff_penerima_name" type="text" class="form-control"/>
                                    <VValidate 
                                        name="Penerima" 
                                        v-model="row.arantib_staff_penerima_name" 
                                        :rules="{required:1, min:3, max:128}"
                                    />
                                    <div class="signing-element mt-1">
                                      <VueSignaturePad
                                          class="sign-container"
                                          width="100%"
                                          height="160px"
                                          ref="ttdPenerima"
                                      />
                                      <div class="mt-2">
                                      <button type="button" @click="undoTTD('ttdPenerima','arantib_staff_penerima')" class="btn btn-sm btn-danger btn-labeled btn-labeled-left se-cleaner">
                                          <b><i class="icon-cross3"></i></b>
                                          <span>Clear</span>
                                      </button>
                                      
                                      <button type="button" @click="output('ttdPenerima','arantib_staff_penerima')" class="btn btn-sm btn-success btn-labeled btn-labeled-left se-cleaner ml-2">
                                          <b><i class="icon-checkmark2"></i></b>
                                      <span>Simpan</span>
                                      </button>
                                      </div>
                                      
                                      <VValidate name="Tanda Tangan Staf Penerima" v-model="row.arantib_staff_penerima"
                                      :rules="{required:1}" />
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </table>
                    -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <div class="text-right">
                <button type="button" @click="back()" class="btn btn-light mr-3">Back</button>
                <button type="submit" class="btn btn-primary">Submit <i class="icon-paperplane ml-2"></i></button>
              </div>
            </div>
          </div>
        </b-form>
      </validation-observer>
    </div>
  </div>
</template>


<script>
import GlobalVue from '@/libs/Global.vue'
import $ from 'jquery'
const _ = global._
import Gen from '@/libs/Gen.js'
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'
import Datepicker from 'vuejs-datepicker'
import { stringSimilarity } from "string-similarity-js"
import PatientInfo from '@/components/Ranap/PatientInfo.vue'

export default {
  extends: GlobalVue,
  components:{ VueTimepicker, Datepicker, PatientInfo },
  data(){
    return {
      rowReg: {},
      menuPage: 'RanapTransferIntraBedah',
      mAlergi: [],
      kelengkapanStatusPasien1 : [
          { text: "Pengkajian pra operasi", value: "Pengkajian pra operasi" },
          { text: "Pengkajian pra anestesi", value: "Pengkajian pra anestesi" },
          { text: "Inform concent Bedah", value: "Inform concent Bedah" },
          { text: "Inform concent Anestesi", value: "Inform concent Anestesi" }
      ],
      kelengkapanStatusPasien2 : [
          { text: "Hasil lab", value: "Hasil lab" },
          { text: "ECG", value: "ECG" },
          { text: "Foto Rontgen", value: "Foto Rontgen" },
          { text: "USG", value: "USG" }
      ],

      penyakitKronis1 : [
          { text: "Diabetes Melitus", value: "Diabetes Melitus" },
          { text: "TB Paru", value: "TB Paru" },
          { text: "Hipertensi", value: "Hipertensi" }
      ],
      penyakitKronis2 : [
          { text: "Hepatitis B-C-A", value: "Hepatitis B-C-A" },
          { text: "HIV/AIDS", value: "HIV/AIDS" },
          { text: "ASTMA", value: "ASTMA" }
      ],

      persiapanPasien1 : [
        { text: "Gelang identitas", value: "Gelang identitas" },
        { text: "Mandi / keramas", value: "Mandi / keramas" },
        { text: "Persiapan kulit", value: "Persiapan kulit" },
        { text: "Pewarna kuku", value: "Pewarna kuku" },
        { text: "Perona pipi", value: "Perona pipi" },
        { text: "Aksesoris", value: "Aksesoris" },
        { text: "Infus, IV Cath No", value: "Infus, IV Cath No" },
        { text: "NGT No", value: "NGT No" },
        { text: "Latihan batuk efektif", value: "Latihan batuk efektif" },
        { text: "Latihan aktifitas", value: "Latihan aktifitas" }
      ],

      persiapanPasien2 : [
        { text: "Oral hygiene", value: "Oral hygiene" },
        { text: "Puasa jam", value: "Puasa jam" },
        { text: "Lipstick", value: "Lipstick" },
        { text: "Eye shadow", value: "Eye shadow" },
        { text: "Gigi palsu", value: "Gigi palsu" },
        { text: "Fiksasi leher", value: "Fiksasi leher" },
        { text: "Foley cath", value: "Foley cath" },
        { text: "Drainage", value: "Drainage" },
        { text: "Soft lens", value: "Soft lens" },
        { text: "Bidai", value: "Bidai" }
      ],

      resepDokter: [],
      mRacikan: [],
      riwayatObat: [],
      mDosis: [],
      mAlatKesehatan: [],
      mAlatInfus: [],
      mPekerjaan: [],

      openFormRacikan: false,
      obatLen: 0,
      currentPage: 1,
      openRacikan: false,
      rowRacikan: {},
      mObatPreferensi: [],

      openRiwayatObat: false,
      selectedObat: '',
      searchObat: '',

      
      patientData: {},
      loading: {
        patientInfo: false,
      },
    }
  },
  mounted() {
    this.apiGet() 
    this.getPatientInfo()

    
    // setTimeout(()=>{
    //   if(this.$refs['ttdStaff']){
    //     this.$refs['ttdStaff'].fromDataURL(this.row.arantib_staff_pengirim)
    //   } 
    //   if(this.$refs['ttdkeluarga']){
    //     this.$refs['ttdkeluarga'].fromDataURL(this.row.arantib_keluarga)
    //   } 
    //   if(this.$refs['ttdPenerima']){
    //     this.$refs['ttdPenerima'].fromDataURL(this.row.arantib_staff_penerima)
    //   } 
    // },2000)     


    
  },

  methods: {
    
    undoTTD(refs,field){
      this.$refs[refs].clearSignature()
      this.row[field] = null
      setTimeout(()=>{
        this.autoSave(this.row,true)
      },100)
    },
    output(refs,field) {
      let { isEmpty, data } = this.$refs[refs].saveSignature()
      if(!isEmpty){
        this.row[field] = data
        setTimeout(()=>{
          this.autoSave(this.row,true)
        },100)
      }
    },

    back() {
      this.$router.back()
    },

    toValidate(val){
      return {...val}
    },

    apiGet(params = {}, page = 1){
        if(!this.pageSlug || !this.$route.query.regId){
            this.$router.push({name : 'Dashboard'}).catch(()=>{})
        }

        if(this.pageSlug || this.$route.name=='Dashboard') this.loadingOverlay = true
        this.data.data = false
        let paramsQuery = Object.keys(this.$route.query).length ? this.apiParams : this.$route.query
        let url = this.menuPage+'/'+this.$route.params.pageSlug
        
        Gen.apiRest(
            "/get/"+url, 
            {
            params: Object.assign({page: page}, paramsQuery, params.query||{})
            }
        ).then(res=>{
            this.loadingOverlay = false
            _.forEach(res.data, (v,k)=>{
                this.$set(this, k, v)
            })
            if(!this.isFound){
                this.$router.push({name : 'Dashboard'}).catch(()=>{})
            }
        }).catch(()=>{
            this.loadingOverlay = false
        })

        // master
        if(this.$route.query.regId){
            Gen.apiRest(
                "/get/"+url+'?master=1', 
                {
                params: Object.assign({page: page}, paramsQuery, params.query||{})
                }
            ).then(res=>{
                console.log(res.data)
                _.forEach(res.data, (v,k)=>{
                    this.$set(this, k, v)
                })
            })
        }
        
    },

    
    getPatientInfo() {
      this.$set(this.loading, 'patientInfo', true)
      Gen.apiRest(
      "/do/" + 'RanapPasienPerawatan', {
          data: {
            type: "get-data-pasien",
            id_registrasi: this.$route.query.regId
          }
      },
      "POST"
      ).then(resp => {
      this.$set(this, 'patientData', resp.data.data)

      this.$set(this.loading, 'patientInfo', false)
      })
    },

    getConfigDynamic(master,value){
        let text = ''
        if(value){
            let index = (master||[]).findIndex(x => x.value == value)
            if(index !== -1){
                text = master[index]['text']
            }
        }
        return text
    },
    
    autoSave: _.debounce(function (data) {
        data.type = 'auto-save'
        if(!data.isEdit){
            Gen.apiRest(
                "/do/"+this.menuPage,
                {data:data}, 
                "POST"
            )
        }
    },1000),


    searchAlergi: _.debounce(function (e) {
      let data = {
          name: e,
          type: 'get-alergi'
      }
      Gen.apiRest(
          "/do/"+this.menuPage,
          {data:data}, 
          "POST"
      ).then(res=>{
          this.mAlergi = res.data.data            
      })
    }, 100),
  
    changeAlergi(e){
      if(!(this.row.arantib_alergi||[]).length){
          this.addAlergi()
      }
    },
    
    addAlergi(){
      this.row.arantib_alergi.push({
          name : '',
          jenis : null,
      })
      this.addMasterAlergi()
    },
    
    addMasterAlergi(){
      for(let i = 0; i < (this.row.arantib_alergi||[]).length; i++){
          let newData = this.row.arantib_alergi[i]
          if((newData.name||"").length && newData.jenis == "Obat"){
              let dataPost = {
                  type : 'auto-save-riwayat',
                  name : newData.name
              }  
              Gen.apiRest(
                  "/do/"+this.menuPage,
                  {data:dataPost}, 
                  "POST"
              )
          }
      }
    },
    
    removeAlergi(k){
      this.row.arantib_alergi.splice(k,1)
    },
    

    doSubmit(){
      this.$refs['VForm'].validate().then(success => {
        if (!success) {
          return this.$swal({
              icon: 'error',
              title: 'Data Tidak Lengkap, Mohon Lengkapi Data Terlebih Dahulu'
          }).then(result => {
              if (result.value) {
                  setTimeout(() => {
                      let inv = []
                      let el = document.querySelectorAll(".label_error:not([style='display: none;'])")
                      console.log(el)
                      for (let i = 0; i < (el || []).length; i++) {
                          if (el[i].style.display !== 'none') {
                          inv.push(el[i].id)
                          }
                      }
                                  
                      if (inv.length) document.getElementById(inv[0]).scrollIntoView({
                          behavior: 'smooth',
                          block: 'center'
                      })
                  }, 500)
              }
          })
        }
        if (success) {
          this.$swal({
              icon: 'warning',
              title: 'Apakah Anda Yakin akan menyimpan data ini?',
              showCancelButton: true,
              confirmButtonText: 'Ya',
              cancelButtonText: 'Tidak, kembali'
          }).then(result => {
            if (result.value) {
              let data = this.row
              data.type = 'submit-data'
              data.resepDokter = this.resepDokter
              data.formType = "PPJP"

              
              this.loadingOverlay = true
              Gen.apiRest(
              "/do/" + this.menuPage, {
                  data: data
              },
              "POST"
              ).then(res => {
                this.loadingOverlay = false
                
                let resp = res.data
                resp.statusType = 200
                this.$swal({
                    title: resp.message,
                    icon: resp.status,
                    confirmButtonText: 'Ok',
                    allowOutsideClick: false,
                    allowEscapeKey: false
                }).then(result => {
                    this.$router.push({ name: 'RanapOperasiPPJP'}).catch(()=>{})   
                })
              }).catch(err => {
                this.loadingOverlay = false
                if (err) {
                  err.statusType = err.status
                  err.status = "error"
                  err.title = err.response?.data?.title
                  err.message = err.response?.data?.message
                  err.messageError = err.message
                }
                this.doSetAlertForm(err)
              })
            }
          })
        }
      })
    },


    // resep
    changePageObat(page, k, act, col) {
        if (act == 'min') {
            if (page > 1) {
                page -= 1
                this[col][k]['currentPage'] -= 1
            }
        } else {
            if (page < this.ceilData(this[col][k]['totalRows'] / 10)) {
                page += 1
                this[col][k]['currentPage'] += 1
            }
        }

        let data = {
            type: 'select-paging-obat',
            page: page,
            idDokter: this.rowReg.aur_dokter_id,
            search: this[col][k]['search']
        }
        Gen.apiRest(
        "/do/" + this.menuPage, {
            data: data
        },
        "POST"
        ).then(res => {
            this.mObatPreferensi = res.data.data
        })
    },

    openObat(v = null) {
        let data = {
            aranapro_aranr_id: this.rowReg.aranr_id,
            aranapro_arano_id: this.row.arano_id,
            aranapro_jenis: 1,
            aranapro_item_id: null,
            aranapro_nama: null,
            aranapro_jumlah: null,
            aranapro_satuan: null,
            aranapro_frekuensi: null,
            aranapro_frekuensi_lainnya: null,
            aranapro_keterangan: null,
            id: null
        }
        if (!this.row.isEdit) {
            data.type = 'add-resep-dokter'
            Gen.apiRest(
                "/do/" + this.menuPage, {
                data: data
                },
                "POST"
            ).then(res => {
                this.apiGet()
            })
        } else {
            this.resepDokter.push(data)
        }

    },
    
    removeObat(v, k) {
        if (!this.row.isEdit) {
            let data = {
                type: 'remove-resep-dokter',
                id: v.aranapro_id
            }
            Gen.apiRest(
                "/do/" + this.menuPage, {
                data: data
                },
                "POST"
            ).then(res => {
                this.resepDokter.splice(k, 1)
            })
        } else {
            this.resepDokter.splice(k, 1)
        }
    },
    
    resetObat() {
        this.loadingOverlay = true
        let data = {
            id: this.row.aurm_id,
            type: 'get-resep-dokter'
        }
        Gen.apiRest(
        "/do/" + this.menuPage, {
            data: data
        },
        "POST"
        ).then(res => {
            let resp = res.data
            this.resepDokter = resp.data
            this.loadingOverlay = false
        })
    },

    
    toRacikan(v) {
        this.loadingOverlay = true
        let data = {
            id: v.aranapro_item_id,
            type: 'get-racikan-data'
        }
        Gen.apiRest(
        "/do/" + this.menuPage, {
            data: data
        },
        "POST"
        ).then(res => {
            this.loadingOverlay = false
            this.rowRacikan = {
                ...v,
                ...res.data.row
            }
            this.mObat = res.data.mObat
            this.openRacikan = true
        })
    },
    
    doSubmitRacikan() {
      this.$refs['VFormRacikan'].validate().then(success => {
        if (success) {
          let data = this.rowRacikan
          let idx = this.resepDokter.findIndex(x => x.aranapro_id == this.rowRacikan.aranapro_id)
          this.resepDokter[idx]['aranapro_satuan'] = this.rowRacikan.aranapro_satuan
          this.resepDokter[idx]['aranapro_jumlah'] = this.rowRacikan.aranapro_jumlah
          this.resepDokter[idx]['aranapro_racikan'] = this.rowRacikan.aranapro_racikan
          this.openFormRacikan = false
        } else {
          setTimeout(() => {
          let inv = []
          let el = document.querySelectorAll('.label_error')
          for (let i = 0; i < (el || []).length; i++) {
              if (el[i].style.display !== 'none') {
              inv.push(el[i].id)
              }
          }
          if (inv.length) document.getElementById(inv[0]).scrollIntoView({
              behavior: 'smooth',
              block: 'center'
          })
          }, 500)
        }
      })
    },
    
    doOpenFormRacikan() {
        let data = {
            type: 'get-all-obat'
        }
        Gen.apiRest(
            "/do/" + this.menuPage, {
                data: data
            },
            "POST"
        ).then(res => {
            this.mObat = res.data.data
            this.openFormRacikan = true
            this.openRacikan = false
        })
    },
    
    addObat() {
        this.rowRacikan.aranapro_racikan.push({
            value: null,
            dosis: null,
            satuan_dosis: null,
            jumlah: null,
            satuan_jumlah: null,
        })
    },
    
    resetData() {
        this.searchObat = ''
        this.openFormRacikan = false
        this.apiGet()
    },

    
    changeJenis(e, k) {
        this.resepDokter[k].aranapro_item_id = null
        this.resepDokter[k].aranapro_frekuensi = null
        this.resepDokter[k].aranapro_frekuensi_lainnya = null
    },
    ceilData(value) {
        return Math.ceil(value)
    },
    selectObat(e, k) {
        let index = this.mObat.findIndex(x => x.value == e)
        if (index !== -1) {
            let data = this.mObat[index]
            this.rowRacikan.aranapro_racikan[k]['dosis'] = data.dosis
            this.rowRacikan.aranapro_racikan[k]['satuan_dosis'] = data.satuan_dosis
            this.rowRacikan.aranapro_racikan[k]['satuan_jumlah'] = data.satuan
        }
    },
    filterObat: _.debounce(function (e, k) {
        if (e) {
            this.resepDokter[k]['search'] = e
            let id = []
            for (let i = 0; i < (this.resepDokter || []).length; i++) {
                if (this.resepDokter[i]['aranapro_item_id'] && this.resepDokter[i]['aranapro_jenis'] == 1) {
                id.push(this.resepDokter[i]['aranapro_item_id'])
                }
            }
            Gen.apiRest('/do/' + this.menuPage, {
                data: {
                    type: 'select-obat',
                    search: e,
                    id: id
                }
            }, 'POST').then(res => {
                this.mObatPreferensi = res.data.data
                this.resepDokter[k]['currentPage'] = 1
                this.resepDokter[k]['totalRows'] = res.data.totalRows
            })
        }
    }, 10),

    
    selectJumlahSatuan(e, k) {
        let data = {
            id: e,
            type: 'get-by-obat'
        }
        Gen.apiRest(
        "/do/" + this.menuPage, {
            data: data
        },
        "POST"
        ).then(res => {
            this.resepDokter[k].aranapro_satuan = res.data.satuan
            this.resepDokter[k].aranapro_nama = res.data.nama
            this.resepDokter[k].aranapro_racikan = null

            this.resepDokter[k].aranapro_dosis = res.data.dosis
            this.resepDokter[k].aranapro_satuan_dosis = res.data.satuan_dosis
            this.resepDokter[k].aranapro_item_lainnya = null

            this.isAlergi(res.data.nama, k)
        })
    },
    
    isAlergi(nama, k) {
        let data = []
        for (let i = 0; i < (this.row.ausd_alergi || []).length; i++) {
            if (this.row.ausd_alergi[i]['jenis'] == 'Obat') {
                data.push(this.row.ausd_alergi[i]['name'])
            }
        }
        let isWarning = 0
        for (let i = 0; i < (data || []).length; i++) {
            let result = stringSimilarity(nama||"", data[i])
            if (result > 0.3) isWarning += 1
        }

        this.resepDokter[k]['aranapro_is_alergi'] = isWarning ? "Y" : "N"
    },
    selectJumlahSatuanInfus(e, k){
      let data = {
          id: e,
          type: 'get-by-infus'
      }
      Gen.apiRest(
      "/do/" + this.menuPage, {
          data: data
      },
      "POST"
      ).then(res => {
          this.resepDokter[k].aranapro_satuan = res.data.satuan
          this.resepDokter[k].aranapro_nama = res.data.nama
          this.resepDokter[k].aranapro_racikan = null

          this.resepDokter[k].aranapro_dosis = null
          this.resepDokter[k].aranapro_satuan_dosis = null
          this.resepDokter[k].aranapro_frekuensi = null
          this.resepDokter[k].aranapro_frekuensi_lainnya = null
          this.resepDokter[k].aranapro_item_lainnya = null
      })
    },
    selectJumlahSatuanAlkes(e, k) {
      let data = {
          id: e,
          type: 'get-by-alkes'
      }
      Gen.apiRest(
      "/do/" + this.menuPage, {
          data: data
      },
      "POST"
      ).then(res => {
          this.resepDokter[k].aranapro_satuan = res.data.satuan
          this.resepDokter[k].aranapro_nama = res.data.nama
          this.resepDokter[k].aranapro_racikan = null

          this.resepDokter[k].aranapro_dosis = null
          this.resepDokter[k].aranapro_satuan_dosis = null
          this.resepDokter[k].aranapro_frekuensi = null
          this.resepDokter[k].aranapro_frekuensi_lainnya = null
          this.resepDokter[k].aranapro_item_lainnya = null
      })
    },
    selectJumlahSatuanRacikan(e, k) {
        let data = {
            id: e,
            type: 'get-by-racikan'
        }
        Gen.apiRest(
        "/do/" + this.menuPage, {
            data: data
        },
        "POST"
        ).then(res => {
            this.resepDokter[k].aranapro_satuan = res.data.satuan
            this.resepDokter[k].aranapro_nama = res.data.nama
            this.resepDokter[k].aranapro_racikan = res.data.data

            this.resepDokter[k].aranapro_dosis = null
            this.resepDokter[k].aranapro_satuan_dosis = null
            this.resepDokter[k].aranapro_frekuensi = null
            this.resepDokter[k].aranapro_frekuensi_lainnya = null
            this.resepDokter[k].aranapro_item_lainnya = null
        })
    },
    isAlergiLabel(nama) {
        let data = []
        for (let i = 0; i < (this.row.ausd_alergi || []).length; i++) {
            if (this.row.ausd_alergi[i]['jenis'] == 'Obat') {
                data.push(this.row.ausd_alergi[i]['name'])
            }
        }
        let isWarning = 0
        for (let i = 0; i < (data || []).length; i++) {
            let result = stringSimilarity(nama||"", data[i])
            if (result > 0.3) isWarning += 1
        }
        return isWarning
    },
    countLen() {
      setTimeout(() => {
          this.obatLen = $('.sp-col ul li').length
      }, 100)
    },
    
    autoSaveResepObat: _.debounce(function (data) {
      let dataPost = {
        type: 'auto-save-resep-obat',
        data: data
      }
      if (!this.row.isEdit) {
        Gen.apiRest(
            "/do/" + this.menuPage, {
            data: dataPost
            },
            "POST"
        )
      }
    }, 1000),
    
    // resep
  },
  watch:{
    row: {
        handler(v) {
            this.autoSave(v)
        },
        deep: true
    },
    tingkatKesadaranTotal(v){
        setTimeout(()=>{
            this.row.arankm_response_gcs = v
        },250)
    },
    resepDokter: {
      handler(v) {
        this.autoSaveResepObat(v)
      },
      deep: true
    },
    'row.arantib_persiapan_pasien'(v){
      if((v||[]).indexOf('Infus, IV Cath No') != -1){
        this.row.arantib_medis_infus = "Y"
        this.row.arantib_medis_infus_text = this.row.arantib_infus_cath_no
      }else{
        this.row.arantib_medis_infus = "N"
        this.row.arantib_medis_infus_text = null
      }

      if((v||[]).indexOf('NGT No') != -1){
        this.row.arantib_medis_ngt = "Y"
        this.row.arantib_medis_ngt_text = this.row.arantib_ngt_no
      }else{
        this.row.arantib_medis_ngt = "N"
        this.row.arantib_medis_ngt_text = null
      }
    },

    'row.arantib_infus_cath_no'(v){
      this.row.arantib_medis_infus_text = this.row.arantib_infus_cath_no
    },
    'row.arantib_ngt_no'(v){
      this.row.arantib_medis_ngt_text = this.row.arantib_ngt_no
    }
  }
}
</script>