<template>
  <div class="content-wrapper overflow-visible">
    <patient-info :patient-data="patientData" :loading="loading.patientInfo" :use-history-data="true" :reg-id="$route.query.regId" />

    <div class="content">
      <validation-observer ref="VForm">
        <b-form @submit.prevent="doSubmit()">
        <div class="card">
          <div class="card-header bg-white">
            <div class="row align-items-center">
              <div class="col-md-auto">
                <div class="row align-items-center">
                  <div class="col-md-auto">
                    <h5 class="card-title font-weight-semibold">Kajian Pra Sedasi/Anestesi</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-header">
            <div class="row">
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-3 mb-2">
                    <div>
                      <label for="MasukRuangIntensive">Menikah:<strong class="text-danger">*</strong></label>
                      <b-form-radio-group
                        class="radio-inline"
                        :options="Config.mr.yesNoOptV2"
                        v-model="row.aranpap_menikah"
                      />
                      <VValidate 
                        name="Menikah" 
                        v-model="row.aranpap_menikah" 
                        :rules="toValidate(mrValidation.aranpap_menikah)"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Pekerjaan<strong class="text-danger">*</strong></label>
                      <div class="d-flex">
                        <v-select style="width:200px" id="label" placeholder="-- Pilih Salah Satu --" v-model="row.aranpap_pekerjaan"
                        :options="mPekerjaan" label="text"
                        :clearable="true" :reduce="v=>v.value"></v-select>
                        
                        <div class="ml-2" v-if="row.aranpap_pekerjaan == 99999">
                          <b-form-input v-model="row.aranpap_pekerjaan_text" style="width: 250px;" type="text" class="form-control" placeholder="Jelaskan"
                            fdprocessedid="9kl27u" />
                          <VValidate 
                            name="Pekerjaan Lainnya" 
                            v-model="row.aranpap_pekerjaan_text" 
                            :rules="toValidate(mrValidation.aranpap_pekerjaan_text)"
                          />
                        
                        </div>
                      </div>
                    
                      <VValidate 
                        name="Pekerjaan" 
                        v-model="row.aranpap_pekerjaan" 
                        :rules="toValidate(mrValidation.aranpap_pekerjaan)"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="card mb-0">
                  <div class="card-header bg_head_panel">
                    <h6 class="card-title font-weight-semibold">Kebiasaan</h6>
                  </div>
                  <div class="card-body p-3">
                    <div class="row">
                      <div class="col-md-4">
                        <div>
                          <label for="perHub">Merokok<strong class="text-danger">*</strong></label>
                          <b-form-radio-group
                            class="custom-inline"
                            :options="Config.mr.yesNoOptV2"
                            v-model="row.aranpap_merokok"
                          />
                          <VValidate 
                            name="Merokok" 
                            v-model="row.aranpap_merokok" 
                            :rules="toValidate(mrValidation.aranpap_merokok)"
                          />
                          <template v-if="row.aranpap_merokok == 'Y'">
                            <b-form-input v-model="row.aranpap_merokok_text" style="width: 250px;" type="text" class="form-control mt-2" placeholder="Sebanyak"/>
                            
                            <VValidate 
                              name="Keterangan Merokok" 
                              v-model="row.aranpap_merokok_text" 
                              :rules="toValidate(mrValidation.aranpap_merokok_text)"
                            />
                          </template>
                        </div>
                      </div>

                      <div class="col-md-4">
                        <div>
                          <label for="perHub">Alkohol<strong class="text-danger">*</strong></label>
                          <b-form-radio-group
                            class="custom-inline"
                            :options="Config.mr.yesNoOptV2"
                            v-model="row.aranpap_alkohol"
                          />
                          <VValidate 
                            name="Alkohol" 
                            v-model="row.aranpap_alkohol" 
                            :rules="toValidate(mrValidation.aranpap_alkohol)"
                          />
                          <template v-if="row.aranpap_alkohol == 'Y'">
                            <b-form-input v-model="row.aranpap_alkohol_text" style="width: 250px;" type="text" class="form-control mt-2" placeholder="Sebanyak"/>
                            
                            <VValidate 
                              name="Keterangan Alkohol" 
                              v-model="row.aranpap_alkohol_text" 
                              :rules="toValidate(mrValidation.aranpap_alkohol_text)"
                            />
                          </template>
                        </div>
                      </div>

                      <div class="col-md-4">
                        <div>
                          <label for="perHub">Kopi/Teh/Soda<strong class="text-danger">*</strong></label>
                          <b-form-radio-group
                            class="custom-inline"
                            :options="Config.mr.yesNoOptV2"
                            v-model="row.aranpap_kopi"
                          />
                          <VValidate 
                            name="Kopi/Teh/Soda" 
                            v-model="row.aranpap_kopi" 
                            :rules="toValidate(mrValidation.aranpap_kopi)"
                          />
                          <template v-if="row.aranpap_kopi == 'Y'">
                            <b-form-input v-model="row.aranpap_kopi_text" style="width: 250px;" type="text" class="form-control mt-2" placeholder="Sebanyak"/>
                            
                            <VValidate 
                              name="Keterangan Kopi/Teh/Soda" 
                              v-model="row.aranpap_kopi_text" 
                              :rules="toValidate(mrValidation.aranpap_kopi_text)"
                            />
                          </template>
                        </div>
                      </div>

                      <div class="col-md-4 mt-3">
                        <div>
                          <label for="perHub">Olahraga Rutin<strong class="text-danger">*</strong></label>
                          <b-form-radio-group
                            class="custom-inline"
                            :options="Config.mr.yesNoOptV2"
                            v-model="row.aranpap_is_olahraga"
                          />
                          <VValidate 
                            name="Olahraga Rutin" 
                            v-model="row.aranpap_is_olahraga" 
                            :rules="toValidate(mrValidation.aranpap_is_olahraga)"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 mt-2 mb-2">
                <div class="card mb-0">
                  <div class="card-header bg_head_panel">
                    <h6 class="card-title font-weight-semibold">Obat Rutin yang di Konsumsi</h6>
                  </div>
                  <div class="card-body p-3">

                    <div class="row">
                      <div class="col-md-4 mt-3">
                        <div>
                          <label for="perHub">Penggunaan aspirin rutin<strong class="text-danger">*</strong></label>
                          <b-form-radio-group
                            class="custom-inline"
                            :options="Config.mr.yesNoOptV2"
                            v-model="row.aranpap_aspirin_rutin"
                          />
                          <VValidate 
                            name="Penggunaan aspirin rutin" 
                            v-model="row.aranpap_aspirin_rutin" 
                            :rules="toValidate(mrValidation.aranpap_aspirin_rutin)"
                          />
                          <template v-if="row.aranpap_aspirin_rutin == 'Y'">
                            <b-form-input v-model="row.aranpap_aspirin_rutin_text" style="width: 250px;" type="text" class="form-control mt-2" placeholder="Dosis Dan Frekuensi"/>
                            
                            <VValidate 
                              name="Keterangan Penggunaan aspirin rutin" 
                              v-model="row.aranpap_aspirin_rutin_text" 
                              :rules="toValidate(mrValidation.aranpap_aspirin_rutin_text)"
                            />
                          </template>
                        </div>
                      </div>
                      <div class="col-md-4 mt-3">
                        <div>
                          <label for="perHub">Obat analgetik<strong class="text-danger">*</strong></label>
                          <b-form-radio-group
                            class="custom-inline"
                            :options="Config.mr.yesNoOptV2"
                            v-model="row.aranpap_anti_sakit"
                          />
                          <VValidate 
                            name="Obat analgetik" 
                            v-model="row.aranpap_anti_sakit" 
                            :rules="toValidate(mrValidation.aranpap_anti_sakit)"
                          />
                          <template v-if="row.aranpap_anti_sakit == 'Y'">
                            <b-form-input v-model="row.aranpap_anti_sakit_text" style="width: 250px;" type="text" class="form-control mt-2" placeholder="Dosis Dan Frekuensi"/>
                            
                            <VValidate 
                              name="Keterangan Obat analgetik" 
                              v-model="row.aranpap_anti_sakit_text" 
                              :rules="toValidate(mrValidation.aranpap_anti_sakit_text)"
                            />
                          </template>
                        </div>
                      </div>
                      <div class="col-md-4 mt-3">
                        <div>
                          <label for="perHub">Injeksi steroid tahun-tahun terakhir<strong
                              class="text-danger">*</strong></label>
                          <b-form-radio-group
                            class="custom-inline"
                            :options="Config.mr.yesNoOptV2"
                            v-model="row.aranpap_injeksi"
                          />
                          <VValidate 
                            name="Injeksi steroid tahun-tahun terakhir" 
                            v-model="row.aranpap_injeksi" 
                            :rules="toValidate(mrValidation.aranpap_injeksi)"
                          />
                          <template v-if="row.aranpap_injeksi == 'Y'">
                            <b-form-input v-model="row.aranpap_injeksi_text" style="width: 250px;" type="text" class="form-control mt-2" placeholder="Dosis Dan Frekuensi"/>
                            
                            <VValidate 
                              name="Keterangan Injeksi steroid tahun-tahun terakhir" 
                              v-model="row.aranpap_injeksi_text" 
                              :rules="toValidate(mrValidation.aranpap_injeksi_text)"
                            />
                          </template>
                        </div>
                      </div>
                      <div class="col-md-7 mt-4">
                        <div class="head_panel_red">
                        <div class="d-flex justify-content-between align-items-center">
                            <h3>ALERGI </h3>
                            <div>
                            <b-form-radio-group
                                @input="changeAlergi($event)"
                                :options="Config.mr.yesNoOpt"
                                v-model="row.aranpap_has_alergi"
                            />

                            <VValidate 
                                name="Alergi" 
                                v-model="row.aranpap_has_alergi" 
                                :rules="toValidate(mrValidation.aranpap_has_alergi)"
                            />
                            </div>
                        </div>
                        </div>
                        
                        <template v-if="row.aranpap_has_alergi == 'Y'">
                            <table class="table table-sm table-bordered">
                                <thead>
                                <tr>
                                    <th width="33%">Jenis</th>
                                    <th>Informasi Alergi</th>
                                    <th width="64"></th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(v,k) in (row.aranpap_alergi||[])" :key="k+'aler'">
                                    <td>
                                        <v-select placeholder="Pilih Jenis" v-model="row.aranpap_alergi[k]['jenis']" :options="Config.mr.jenisAlergi" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                                        <VValidate 
                                            :name="'Obat '+(k+1)" 
                                            v-model="row.aranpap_alergi[k]['jenis']" 
                                            :rules="{required:1}"
                                        />
                                    </td>
                                    <td>
                                        <vue-typeahead-bootstrap
                                        v-model="row.aranpap_alergi[k]['name']"
                                        :data="mAlergi" @input="searchAlergi(row.aranpap_alergi[k]['name'])"
                                        placeholder="Pilih Alergi"
                                        />
                                        
                                        <VValidate 
                                            :name="'Obat '+(k+1)" 
                                            v-model="row.aranpap_alergi[k]['name']" 
                                            :rules="{required:1}"
                                        />
                                    </td>
                                    <td>
                                    <a href="javascript:;" @click="removeAlergi(k)" data-popup="tooltip" title="Hapus" class="btn btn-sm btn-icon border-danger rounded-round text-danger-800 alpha-danger"><i class="icon-trash"></i></a>
                                    </td>
                                </tr>
                                </tbody>
                                <tfoot>
                                <tr>
                                    <td colspan="3" class="text-center">
                                    <a href="javascript:;" @click="addAlergi()" class="btn btn-sm alpha-info border-info"><i class="icon-plus2 mr-1"></i>Tambah</a>
                                    </td>
                                </tr>
                                </tfoot>
                            </table>
                            <span class="text-warning d-none" id="alergi-typing">User Lain Sedang Mengisi ...</span>            
                        </template>
                        <template v-else>
                            <table class="table table-sm table-bordered">
                                <thead>
                                <tr>
                                    <th width="33%">Jenis</th>
                                    <th>Informasi Alergi</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>
                                        <v-select placeholder="Pilih Jenis" disabled :options="Config.mr.jenisAlergi" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                                    </td>
                                    <td>
                                    <input disabled type="text" class="form-control" placeholder="cth. amoxicilin">
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </template>
                      </div>

                      <div class="col-md-5 mt-3">
                        <div class="row">
                          <div class="col-md-12 mt-3">
                            <div>
                              <label for="perHub">Alergi latek <strong class="text-danger">*</strong></label>
                              <b-form-radio-group
                                class="radio-inline"
                                :options="Config.mr.yesNoOptV2"
                                v-model="row.aranpap_alergi_latek"
                              />
                              <VValidate 
                                name="Alergi latek" 
                                v-model="row.aranpap_alergi_latek" 
                                :rules="toValidate(mrValidation.aranpap_alergi_latek)"
                              />
                            </div>
                          </div>
                          <div class="col-md-12 mt-3">
                            <div>
                              <label for="perHub">Alergi plester <strong class="text-danger">*</strong></label>
                              <b-form-radio-group
                                class="radio-inline"
                                :options="Config.mr.yesNoOptV2"
                                v-model="row.aranpap_alergi_plester"
                              />
                              <VValidate 
                                name="Alergi plester" 
                                v-model="row.aranpap_alergi_plester" 
                                :rules="toValidate(mrValidation.aranpap_alergi_plester)"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              

              <div class="col-12">
                <div class="card mb-0">
                  <div class="card-header bg_head_panel">
                    <h6 class="card-title font-weight-semibold">Riwayat Penyakit Pasien</h6>
                  </div>
                  <div class="card-body p-3">
                    <div class="row">
                      <div class="col-md-12">
                        <h6 class="font-weight-semibold text-indigo">Apakah pasien pernah menderita penyakit di bawah
                          ini: </h6>
                      </div>
                      <div class="col-md-12">
                        <div class="table-responsive">
                          <table class="table table-striped table-sm table-bordered">
                            <tbody>
                              <tr>  
                                <td>Perdarahan yang tidak normal </td>
                                <td>
                                  <div class="d-flex" style="align-items: center;">
                                    <b-form-radio-group
                                      class="radio-inline"
                                      :options="Config.mr.yesNoOptV2"
                                      v-model="row.aranpap_riwayat_pas_pendarahan"
                                    />
                                    <div class="ml-2" v-if="row.aranpap_riwayat_pas_pendarahan == 'Y'">
                                      <b-form-input v-model="row.aranpap_riwayat_pas_pendarahan_text" style="width: 250px;" type="text" class="form-control" placeholder="Jelaskan"
                                        fdprocessedid="9kl27u" />
                                    </div>
                                  </div>
                                  <VValidate 
                                    name="Perdarahan yang tidak normal" 
                                    v-model="row.aranpap_riwayat_pas_pendarahan" 
                                    :rules="toValidate(mrValidation.aranpap_riwayat_pas_pendarahan)"
                                  />
                                  <VValidate 
                                    v-if="row.aranpap_riwayat_pas_pendarahan == 'Y'"
                                    name="Jelaskan Perdarahan yang tidak normal" 
                                    v-model="row.aranpap_riwayat_pas_pendarahan_text" 
                                    :rules="toValidate(mrValidation.aranpap_riwayat_pas_pendarahan_text)"
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td>Pembekuan darah tidak normal </td>
                                <td>
                                  <div class="d-flex" style="align-items: center;">
                                    <b-form-radio-group
                                      class="radio-inline"
                                      :options="Config.mr.yesNoOptV2"
                                      v-model="row.aranpap_riwayat_pas_pembekuan"
                                    />
                                    <div class="ml-2" v-if="row.aranpap_riwayat_pas_pembekuan == 'Y'">
                                      <b-form-input v-model="row.aranpap_riwayat_pas_pembekuan_text" style="width: 250px;" type="text" class="form-control" placeholder="Jelaskan"
                                        fdprocessedid="9kl27u" />
                                    </div>
                                  </div>
                                  <VValidate 
                                    name="Pembekuan darah tidak normal" 
                                    v-model="row.aranpap_riwayat_pas_pembekuan" 
                                    :rules="toValidate(mrValidation.aranpap_riwayat_pas_pembekuan)"
                                  />
                                  <VValidate 
                                    v-if="row.aranpap_riwayat_pas_pembekuan == 'Y'"
                                    name="Jelaskan Pembekuan darah tidak normal" 
                                    v-model="row.aranpap_riwayat_pas_pembekuan_text" 
                                    :rules="toValidate(mrValidation.aranpap_riwayat_pas_pembekuan_text)"
                                  />
                                </td>
                              </tr>

                              <tr>
                                <td>Sakit Maag </td>
                                <td>
                                  <div class="d-flex" style="align-items: center;">
                                    <b-form-radio-group
                                      class="radio-inline"
                                      :options="Config.mr.yesNoOptV2"
                                      v-model="row.aranpap_riwayat_pas_maag"
                                    />
                                    <div class="ml-2" v-if="row.aranpap_riwayat_pas_maag == 'Y'">
                                      <b-form-input v-model="row.aranpap_riwayat_pas_maag_text" style="width: 250px;" type="text" class="form-control" placeholder="Jelaskan"
                                        fdprocessedid="9kl27u" />
                                    </div>
                                  </div>
                                  <VValidate 
                                    name="Sakit Maag" 
                                    v-model="row.aranpap_riwayat_pas_maag" 
                                    :rules="toValidate(mrValidation.aranpap_riwayat_pas_maag)"
                                  />
                                  <VValidate 
                                    v-if="row.aranpap_riwayat_pas_maag == 'Y'"
                                    name="Jelaskan Sakit Maag" 
                                    v-model="row.aranpap_riwayat_pas_maag_text" 
                                    :rules="toValidate(mrValidation.aranpap_riwayat_pas_maag_text)"
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td>Anemia </td>
                                <td>
                                  <div class="d-flex" style="align-items: center;">
                                    <b-form-radio-group
                                      class="radio-inline"
                                      :options="Config.mr.yesNoOptV2"
                                      v-model="row.aranpap_riwayat_pas_anemia"
                                    />
                                    <div class="ml-2" v-if="row.aranpap_riwayat_pas_anemia == 'Y'">
                                      <b-form-input v-model="row.aranpap_riwayat_pas_anemia_text" style="width: 250px;" type="text" class="form-control" placeholder="Jelaskan"
                                        fdprocessedid="9kl27u" />
                                    </div>
                                  </div>
                                  <VValidate 
                                    name="Anemia" 
                                    v-model="row.aranpap_riwayat_pas_anemia" 
                                    :rules="toValidate(mrValidation.aranpap_riwayat_pas_anemia)"
                                  />
                                  <VValidate 
                                    v-if="row.aranpap_riwayat_pas_anemia == 'Y'"
                                    name="Jelaskan Anemia" 
                                    v-model="row.aranpap_riwayat_pas_anemia_text" 
                                    :rules="toValidate(mrValidation.aranpap_riwayat_pas_anemia_text)"
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td>Asma </td>
                                <td>
                                  <div class="d-flex" style="align-items: center;">
                                    <b-form-radio-group
                                      class="radio-inline"
                                      :options="Config.mr.yesNoOptV2"
                                      v-model="row.aranpap_riwayat_pas_asma"
                                    />
                                    <div class="ml-2" v-if="row.aranpap_riwayat_pas_asma == 'Y'">
                                      <b-form-input v-model="row.aranpap_riwayat_pas_asma_text" style="width: 250px;" type="text" class="form-control" placeholder="Jelaskan"
                                        fdprocessedid="9kl27u" />
                                    </div>
                                  </div>
                                  <VValidate 
                                    name="Asma" 
                                    v-model="row.aranpap_riwayat_pas_asma" 
                                    :rules="toValidate(mrValidation.aranpap_riwayat_pas_asma)"
                                  />
                                  <VValidate 
                                    v-if="row.aranpap_riwayat_pas_asma == 'Y'"
                                    name="Jelaskan Asma" 
                                    v-model="row.aranpap_riwayat_pas_asma_text" 
                                    :rules="toValidate(mrValidation.aranpap_riwayat_pas_asma_text)"
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td>Sesak Nafas </td>
                                <td>
                                  <div class="d-flex" style="align-items: center;">
                                    <b-form-radio-group
                                      class="radio-inline"
                                      :options="Config.mr.yesNoOptV2"
                                      v-model="row.aranpap_riwayat_pas_sesak_nafas"
                                    />
                                    <div class="ml-2" v-if="row.aranpap_riwayat_pas_sesak_nafas == 'Y'">
                                      <b-form-input v-model="row.aranpap_riwayat_pas_sesak_nafas_text" style="width: 250px;" type="text" class="form-control" placeholder="Jelaskan"
                                        fdprocessedid="9kl27u" />
                                    </div>
                                  </div>
                                  <VValidate 
                                    name="Sesak Nafas" 
                                    v-model="row.aranpap_riwayat_pas_sesak_nafas" 
                                    :rules="toValidate(mrValidation.aranpap_riwayat_pas_sesak_nafas)"
                                  />
                                  <VValidate 
                                    v-if="row.aranpap_riwayat_pas_sesak_nafas == 'Y'"
                                    name="Jelaskan Sesak Nafas" 
                                    v-model="row.aranpap_riwayat_pas_sesak_nafas_text" 
                                    :rules="toValidate(mrValidation.aranpap_riwayat_pas_sesak_nafas_text)"
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td>Diabetes </td>
                                <td>
                                  <div class="d-flex" style="align-items: center;">
                                    <b-form-radio-group
                                      class="radio-inline"
                                      :options="Config.mr.yesNoOptV2"
                                      v-model="row.aranpap_riwayat_pas_diabetes"
                                    />
                                    <div class="ml-2" v-if="row.aranpap_riwayat_pas_diabetes == 'Y'">
                                      <b-form-input v-model="row.aranpap_riwayat_pas_diabetes_text" style="width: 250px;" type="text" class="form-control" placeholder="Jelaskan"
                                        fdprocessedid="9kl27u" />
                                    </div>
                                  </div>
                                  <VValidate 
                                    name="Diabetes" 
                                    v-model="row.aranpap_riwayat_pas_diabetes" 
                                    :rules="toValidate(mrValidation.aranpap_riwayat_pas_diabetes)"
                                  />
                                  <VValidate 
                                    v-if="row.aranpap_riwayat_pas_diabetes == 'Y'"
                                    name="Jelaskan Diabetes" 
                                    v-model="row.aranpap_riwayat_pas_diabetes_text" 
                                    :rules="toValidate(mrValidation.aranpap_riwayat_pas_diabetes_text)"
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td>Pingsan </td>
                                <td>
                                  <div class="d-flex" style="align-items: center;">
                                    <b-form-radio-group
                                      class="radio-inline"
                                      :options="Config.mr.yesNoOptV2"
                                      v-model="row.aranpap_riwayat_pas_pingsan"
                                    />
                                    <div class="ml-2" v-if="row.aranpap_riwayat_pas_pingsan == 'Y'">
                                      <b-form-input v-model="row.aranpap_riwayat_pas_pingsan_text" style="width: 250px;" type="text" class="form-control" placeholder="Jelaskan"
                                        fdprocessedid="9kl27u" />
                                    </div>
                                  </div>
                                  <VValidate 
                                    name="Pingsan" 
                                    v-model="row.aranpap_riwayat_pas_pingsan" 
                                    :rules="toValidate(mrValidation.aranpap_riwayat_pas_pingsan)"
                                  />
                                  <VValidate 
                                    v-if="row.aranpap_riwayat_pas_pingsan == 'Y'"
                                    name="Jelaskan Pingsan" 
                                    v-model="row.aranpap_riwayat_pas_pingsan_text" 
                                    :rules="toValidate(mrValidation.aranpap_riwayat_pas_pingsan_text)"
                                  />
                                </td>
                              </tr>

                              <tr>
                                <td>Serangan Jantung / nyeri dada </td>
                                <td>
                                  <div class="d-flex" style="align-items: center;">
                                    <b-form-radio-group
                                      class="radio-inline"
                                      :options="Config.mr.yesNoOptV2"
                                      v-model="row.aranpap_riwayat_pas_serangan_jantung"
                                    />
                                    <div class="ml-2" v-if="row.aranpap_riwayat_pas_serangan_jantung == 'Y'">
                                      <b-form-input v-model="row.aranpap_riwayat_pas_serangan_jantung_text" style="width: 250px;" type="text" class="form-control" placeholder="Jelaskan"
                                        fdprocessedid="9kl27u" />
                                    </div>
                                  </div>
                                  <VValidate 
                                    name="Serangan Jantung / nyeri dada" 
                                    v-model="row.aranpap_riwayat_pas_serangan_jantung" 
                                    :rules="toValidate(mrValidation.aranpap_riwayat_pas_serangan_jantung)"
                                  />
                                  <VValidate 
                                    v-if="row.aranpap_riwayat_pas_serangan_jantung == 'Y'"
                                    name="Jelaskan Serangan Jantung / nyeri dada" 
                                    v-model="row.aranpap_riwayat_pas_serangan_jantung_text" 
                                    :rules="toValidate(mrValidation.aranpap_riwayat_pas_serangan_jantung_text)"
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td>Hepatitis </td>
                                <td>
                                  <div class="d-flex" style="align-items: center;">
                                    <b-form-radio-group
                                      class="radio-inline"
                                      :options="Config.mr.yesNoOptV2"
                                      v-model="row.aranpap_riwayat_pas_hepatitis"
                                    />
                                    <div class="ml-2" v-if="row.aranpap_riwayat_pas_hepatitis == 'Y'">
                                      <b-form-input v-model="row.aranpap_riwayat_pas_hepatitis_text" style="width: 250px;" type="text" class="form-control" placeholder="Jelaskan"
                                        fdprocessedid="9kl27u" />
                                    </div>
                                  </div>
                                  <VValidate 
                                    name="Hepatitis" 
                                    v-model="row.aranpap_riwayat_pas_hepatitis" 
                                    :rules="toValidate(mrValidation.aranpap_riwayat_pas_hepatitis)"
                                  />
                                  <VValidate 
                                    v-if="row.aranpap_riwayat_pas_hepatitis == 'Y'"
                                    name="Jelaskan Hepatitis" 
                                    v-model="row.aranpap_riwayat_pas_hepatitis_text" 
                                    :rules="toValidate(mrValidation.aranpap_riwayat_pas_hepatitis_text)"
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td>Hipertensi </td>
                                <td>
                                  <div class="d-flex" style="align-items: center;">
                                    <b-form-radio-group
                                      class="radio-inline"
                                      :options="Config.mr.yesNoOptV2"
                                      v-model="row.aranpap_riwayat_pas_hipertensi"
                                    />
                                    <div class="ml-2" v-if="row.aranpap_riwayat_pas_hipertensi == 'Y'">
                                      <b-form-input v-model="row.aranpap_riwayat_pas_hipertensi_text" style="width: 250px;" type="text" class="form-control" placeholder="Jelaskan"
                                        fdprocessedid="9kl27u" />
                                    </div>
                                  </div>
                                  <VValidate 
                                    name="Hipertensi" 
                                    v-model="row.aranpap_riwayat_pas_hipertensi" 
                                    :rules="toValidate(mrValidation.aranpap_riwayat_pas_hipertensi)"
                                  />
                                  <VValidate 
                                    v-if="row.aranpap_riwayat_pas_hipertensi == 'Y'"
                                    name="Jelaskan Hipertensi" 
                                    v-model="row.aranpap_riwayat_pas_hipertensi_text" 
                                    :rules="toValidate(mrValidation.aranpap_riwayat_pas_hipertensi_text)"
                                  />
                                </td>
                              </tr>

                              <tr>
                                <td>Sumbatan </td>
                                <td>
                                  <div class="d-flex" style="align-items: center;">
                                    <b-form-radio-group
                                      class="radio-inline"
                                      :options="Config.mr.yesNoOptV2"
                                      v-model="row.aranpap_riwayat_pas_sumbatan"
                                    />
                                    <div class="ml-2" v-if="row.aranpap_riwayat_pas_sumbatan == 'Y'">
                                      <b-form-input v-model="row.aranpap_riwayat_pas_sumbatan_text" style="width: 250px;" type="text" class="form-control" placeholder="Jelaskan"
                                        fdprocessedid="9kl27u" />
                                    </div>
                                  </div>
                                  <VValidate 
                                    name="Sumbatan" 
                                    v-model="row.aranpap_riwayat_pas_sumbatan" 
                                    :rules="toValidate(mrValidation.aranpap_riwayat_pas_sumbatan)"
                                  />
                                  <VValidate 
                                    v-if="row.aranpap_riwayat_pas_sumbatan == 'Y'"
                                    name="Jelaskan Sumbatan" 
                                    v-model="row.aranpap_riwayat_pas_sumbatan_text" 
                                    :rules="toValidate(mrValidation.aranpap_riwayat_pas_sumbatan_text)"
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-4 mt-3">
                        <div>
                          <label for="perHub">Apakah pasien pernah mendapat transfusi darah?<strong
                              class="text-danger">*</strong></label>
                          <div class="d-flex" style="align-items: center;">
                            <b-form-radio-group
                              class="radio-inline"
                              :options="Config.mr.yesNoOptV2"
                              v-model="row.aranpap_transfusi"
                            />
                            <div class="ml-2" v-if="row.aranpap_transfusi == 'Y'">
                              <b-form-textarea v-model="row.aranpap_transfusi_text" style="width: 250px;" type="text" class="form-control" placeholder="Jelaskan"
                                fdprocessedid="9kl27u" />
                            </div>
                          </div>
                          <VValidate 
                            name="Transfusi Darah" 
                            v-model="row.aranpap_transfusi" 
                            :rules="toValidate(mrValidation.aranpap_transfusi)"
                          />
                          <VValidate 
                            v-if="row.aranpap_transfusi == 'Y'"
                            name="Jelaskan Transfusi Darah" 
                            v-model="row.aranpap_transfusi_text" 
                            :rules="toValidate(mrValidation.aranpap_transfusi_text)"
                          />
                        </div>
                      </div>
                      <div class="col-md-4 mt-3">
                        <div>
                          <label for="perHub">Apakah pasien pernah diperiksa untuk diagnosa HIV?<strong
                              class="text-danger">*</strong></label>

                          <div class="d-flex" style="align-items: center;">
                            <b-form-radio-group
                              class="radio-inline"
                              :options="Config.mr.yesNoOptV2"
                              v-model="row.aranpap_diagnosa_hiv"
                            />
                            <div class="ml-2" v-if="row.aranpap_diagnosa_hiv == 'Y'">
                              <b-form-textarea v-model="row.aranpap_diagnosa_hiv_text" style="width: 250px;" type="text" class="form-control" placeholder="Jelaskan"
                                fdprocessedid="9kl27u" />
                            </div>
                          </div>
                          <VValidate 
                            name="Diagnosa HIV" 
                            v-model="row.aranpap_diagnosa_hiv" 
                            :rules="toValidate(mrValidation.aranpap_diagnosa_hiv)"
                          />
                          <VValidate 
                            v-if="row.aranpap_diagnosa_hiv == 'Y'"
                            name="Jelaskan Diagnosa HIV" 
                            v-model="row.aranpap_diagnosa_hiv_text" 
                            :rules="toValidate(mrValidation.aranpap_diagnosa_hiv_text)"
                          />

                        </div>
                      </div>
                      <div class="col-md-4 mt-3" v-if="row.aranpap_diagnosa_hiv == 'Y'">
                        <div>
                          <label for="perHub">Hasil pemeriksaan HIV <strong class="text-danger">*</strong></label>
                          <b-form-radio-group
                            class="radio-inline"
                            :options="Config.mr.positifNegatifOpt"
                            v-model="row.aranpap_hasil_hiv"
                          />
                          <VValidate 
                            name="Hasil pemeriksaan HIV" 
                            v-model="row.aranpap_hasil_hiv" 
                            :rules="toValidate(mrValidation.aranpap_hasil_hiv)"
                          />
                        </div>
                      </div>
                      <div class="col-md-12 mt-3">
                        <h6 class="font-weight-semibold text-indigo">Apakah pasien memakai: </h6>
                        <div class="row">
                          <div class="col-md-3">
                            <div>
                              <label for="perHub">Lensa Kontak <strong class="text-danger">*</strong></label>
                              <b-form-radio-group
                                class="radio-inline"
                                :options="Config.mr.yesNoOptV2"
                                v-model="row.aranpap_lensa_kontak"
                              />
                              <VValidate 
                                name="Lensa Kontak" 
                                v-model="row.aranpap_lensa_kontak" 
                                :rules="toValidate(mrValidation.aranpap_lensa_kontak)"
                              />
                            </div>
                          </div>
                          <div class="col-md-3">
                            <div>
                              <label for="perHub">Kacamata <strong class="text-danger">*</strong></label>
                              <b-form-radio-group
                                class="radio-inline"
                                :options="Config.mr.yesNoOptV2"
                                v-model="row.aranpap_kacamata"
                              />
                              <VValidate 
                                name="Kacamata" 
                                v-model="row.aranpap_kacamata" 
                                :rules="toValidate(mrValidation.aranpap_kacamata)"
                              />
                            </div>
                          </div>
                          <div class="col-md-3">
                            <div>
                              <label for="perHub">Alat bantu dengar <strong class="text-danger">*</strong></label>
                              <b-form-radio-group
                                class="radio-inline"
                                :options="Config.mr.yesNoOptV2"
                                v-model="row.aranpap_alat_bantu_dengar"
                              />
                              <VValidate 
                                name="Alat Bantu Dengar" 
                                v-model="row.aranpap_alat_bantu_dengar" 
                                :rules="toValidate(mrValidation.aranpap_alat_bantu_dengar)"
                              />
                            </div>
                          </div>
                          <div class="col-md-3">
                            <div>
                              <label for="perHub">Gigi Palsu <strong class="text-danger">*</strong></label>
                              <b-form-radio-group
                                class="radio-inline"
                                :options="Config.mr.yesNoOptV2"
                                v-model="row.aranpap_gigi_palsu"
                              />
                              <VValidate 
                                name="Gigi Palsu" 
                                v-model="row.aranpap_gigi_palsu" 
                                :rules="toValidate(mrValidation.aranpap_gigi_palsu)"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-12 mt-3">
                        <h6 class="font-weight-semibold text-indigo">Riwayat operasi, tahun dan jenis operasi: <span
                            style="cursor:help;" v-b-tooltip.hover
                            title="Tuliskan jenis anestesi yang digunakan serta komplikasi/reaksi yang dialami oleh pasien"><i
                              class="icon-info22 text-indigo"></i></span> </h6>
                        <div class="row">
                          <div class="col-md-12 mb-3">
                            <div>
                              <label for="perHub">Apakah Pasien Memiliki Riwayat Operasi<strong
                                  class="text-danger">*</strong></label>
                              <b-form-radio-group
                                class="radio-inline"
                                :options="Config.mr.yesNoOptV2"
                                v-model="row.aranpap_riwayat_operasi"
                              />
                              <VValidate 
                                name="Riwayat Operasi" 
                                v-model="row.aranpap_riwayat_operasi" 
                                :rules="toValidate(mrValidation.aranpap_riwayat_operasi)"
                              />
                            </div>
                          </div>
                          <template v-if="row.aranpap_riwayat_operasi == 'Y'">
                          <div class="col-md-4">
                            <div>
                              <label for="perHub">Anestesi local-komplikasi/reaksi:</label>
                              <b-form-textarea v-model="row.aranpap_anestesi_local_komplikasi" rows="3" class="form-control"></b-form-textarea>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div>
                              <label for="perHub"> Anestesi regional-komplikasi/reaksi:</label>
                              <b-form-textarea v-model="row.aranpap_anestesi_regional_komplikasi" rows="3" class="form-control"></b-form-textarea>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div>
                              <label for="perHub"> Anesthesia umum-komplikasi/reaksi:</label>
                              <b-form-textarea  v-model="row.aranpap_anestesi_umum_komplikasi" rows="3" class="form-control"></b-form-textarea>
                            </div>
                          </div>

                          <div class="col-md-4 mt-2">
                            <div>
                              <label for="perHub"> Tanggal terakhir kali periksa kesehatan ke dokter:</label>
                              <div class="input-group">
                                <div class="input-group-prepend">
                                  <span class="input-group-text"><i class="icon-calendar22"></i></span>
                                </div>
                                <datepicker input-class="form-control transparent" class="my-datepicker"
                                    calendar-class="my-datepicker_calendar"
                                    v-model="row.aranpap_tanggal_terakhir_diperiksa">
                                </datepicker>
                              </div>
                            </div>
                          </div>

                          <div class="col-md-4 mt-2">
                            <div>
                              <label for="perHub"> Dimana:</label>
                              <b-form-input v-model="row.aranpap_diperiksa_dimana" type="text" class="form-control"/>
                            </div>
                          </div>

                          <div class="col-md-4 mt-2">
                            <div>
                              <label for="perHub"> Untuk Penyakit Gangguan Apa:</label>
                              <b-form-textarea v-model="row.aranpap_untuk_penyakit_apa" rows="3" class="form-control"></b-form-textarea>
                            </div>
                          </div>
                          </template>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-12 mb-2">
                <div class="card mb-0">
                  <div class="card-header bg_head_panel">
                    <h6 class="card-title font-weight-semibold">Riwayat Keluarga </h6>
                  </div>
                  <div class="card-body p-3">
                    <div class="row">
                      <div class="col-md-12">
                        <h6 class="font-weight-semibold text-indigo">Apakah keluarga mendapat permasalahan seperti di
                          bawah ini: </h6>
                      </div>

                      <div class="col-md-12">
                        <div class="table-responsive">
                          <table class="table table-striped table-sm table-bordered">
                            <tbody>
                              <tr>
                                <td>Serangan jantung </td>
                                <td>
                                  <div class="d-flex" style="align-items: center;">
                                    <b-form-radio-group
                                      class="radio-inline"
                                      :options="Config.mr.yesNoOptV2"
                                      v-model="row.aranpap_riwayat_kel_jantung"
                                    />
                                    <div class="ml-2" v-if="row.aranpap_riwayat_kel_jantung == 'Y'">
                                      <b-form-input v-model="row.aranpap_riwayat_kel_jantung_text" style="width: 250px;" type="text" class="form-control" placeholder="Jelaskan"
                                        fdprocessedid="9kl27u" />
                                    </div>
                                  </div>
                                  <VValidate 
                                    name="Serangan Jantung" 
                                    v-model="row.aranpap_riwayat_kel_jantung" 
                                    :rules="toValidate(mrValidation.aranpap_riwayat_kel_jantung)"
                                  />
                                  <VValidate 
                                    v-if="row.aranpap_riwayat_kel_jantung == 'Y'"
                                    name="Jelaskan Serangan Jantung" 
                                    v-model="row.aranpap_riwayat_kel_jantung_text" 
                                    :rules="toValidate(mrValidation.aranpap_riwayat_kel_jantung_text)"
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td>Hipertensi </td>
                                <td>
                                  <div class="d-flex" style="align-items: center;">
                                    <b-form-radio-group
                                      class="radio-inline"
                                      :options="Config.mr.yesNoOptV2"
                                      v-model="row.aranpap_riwayat_kel_hipertensi"
                                    />
                                    <div class="ml-2" v-if="row.aranpap_riwayat_kel_hipertensi == 'Y'">
                                      <b-form-input v-model="row.aranpap_riwayat_kel_hipertensi_text" style="width: 250px;" type="text" class="form-control" placeholder="Jelaskan"
                                        fdprocessedid="9kl27u" />
                                    </div>
                                  </div>
                                  <VValidate 
                                    name="Hipertensi" 
                                    v-model="row.aranpap_riwayat_kel_hipertensi" 
                                    :rules="toValidate(mrValidation.aranpap_riwayat_kel_hipertensi)"
                                  />
                                  <VValidate 
                                    v-if="row.aranpap_riwayat_kel_hipertensi == 'Y'"
                                    name="Jelaskan Hipertensi" 
                                    v-model="row.aranpap_riwayat_kel_hipertensi_text" 
                                    :rules="toValidate(mrValidation.aranpap_riwayat_kel_hipertensi_text)"
                                  />
                                </td>
                              </tr>

                              <tr>
                                <td>Tuberkulose </td>
                                <td>
                                  <div class="d-flex" style="align-items: center;">
                                    <b-form-radio-group
                                      class="radio-inline"
                                      :options="Config.mr.yesNoOptV2"
                                      v-model="row.aranpap_riwayat_kel_tuberkulose"
                                    />
                                    <div class="ml-2" v-if="row.aranpap_riwayat_kel_tuberkulose == 'Y'">
                                      <b-form-input v-model="row.aranpap_riwayat_kel_tuberkulose_text" style="width: 250px;" type="text" class="form-control" placeholder="Jelaskan"
                                        fdprocessedid="9kl27u" />
                                    </div>
                                  </div>
                                  <VValidate 
                                    name="Tuberkulose" 
                                    v-model="row.aranpap_riwayat_kel_tuberkulose" 
                                    :rules="toValidate(mrValidation.aranpap_riwayat_kel_tuberkulose)"
                                  />
                                  <VValidate 
                                    v-if="row.aranpap_riwayat_kel_tuberkulose == 'Y'"
                                    name="Jelaskan Tuberkulose" 
                                    v-model="row.aranpap_riwayat_kel_tuberkulose_text" 
                                    :rules="toValidate(mrValidation.aranpap_riwayat_kel_tuberkulose_text)"
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td>Perdarahan tidak normal </td>
                                <td>
                                  <div class="d-flex" style="align-items: center;">
                                    <b-form-radio-group
                                      class="radio-inline"
                                      :options="Config.mr.yesNoOptV2"
                                      v-model="row.aranpap_riwayat_kel_pendarahan_tdk_normal"
                                    />
                                    <div class="ml-2" v-if="row.aranpap_riwayat_kel_pendarahan_tdk_normal == 'Y'">
                                      <b-form-input v-model="row.aranpap_riwayat_kel_pendarahan_tdk_normal_text" style="width: 250px;" type="text" class="form-control" placeholder="Jelaskan"
                                        fdprocessedid="9kl27u" />
                                    </div>
                                  </div>
                                  <VValidate 
                                    name="Perdarahan tidak normal" 
                                    v-model="row.aranpap_riwayat_kel_pendarahan_tdk_normal" 
                                    :rules="toValidate(mrValidation.aranpap_riwayat_kel_pendarahan_tdk_normal)"
                                  />
                                  <VValidate 
                                    v-if="row.aranpap_riwayat_kel_pendarahan_tdk_normal == 'Y'"
                                    name="Jelaskan Perdarahan tidak normal" 
                                    v-model="row.aranpap_riwayat_kel_pendarahan_tdk_normal_text" 
                                    :rules="toValidate(mrValidation.aranpap_riwayat_kel_pendarahan_tdk_normal_text)"
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td>Pembekuan darah tidak normal </td>
                                <td>
                                  <div class="d-flex" style="align-items: center;">
                                    <b-form-radio-group
                                      class="radio-inline"
                                      :options="Config.mr.yesNoOptV2"
                                      v-model="row.aranpap_riwayat_kel_pembekuan_tdk_normal"
                                    />
                                    <div class="ml-2" v-if="row.aranpap_riwayat_kel_pembekuan_tdk_normal == 'Y'">
                                      <b-form-input v-model="row.aranpap_riwayat_kel_pembekuan_tdk_normal_text" style="width: 250px;" type="text" class="form-control" placeholder="Jelaskan"
                                        fdprocessedid="9kl27u" />
                                    </div>
                                  </div>
                                  <VValidate 
                                    name="Pembekuan darah tidak normal" 
                                    v-model="row.aranpap_riwayat_kel_pembekuan_tdk_normal" 
                                    :rules="toValidate(mrValidation.aranpap_riwayat_kel_pembekuan_tdk_normal)"
                                  />
                                  <VValidate 
                                    v-if="row.aranpap_riwayat_kel_pembekuan_tdk_normal == 'Y'"
                                    name="Jelaskan Pembekuan darah tidak normal" 
                                    v-model="row.aranpap_riwayat_kel_pembekuan_tdk_normal_text" 
                                    :rules="toValidate(mrValidation.aranpap_riwayat_kel_pembekuan_tdk_normal_text)"
                                  />
                                </td>
                              </tr>

                              <tr>
                                <td>Permasalahan dalam pembiusan </td>
                                <td>
                                  <div class="d-flex" style="align-items: center;">
                                    <b-form-radio-group
                                      class="radio-inline"
                                      :options="Config.mr.yesNoOptV2"
                                      v-model="row.aranpap_riwayat_kel_permasalahan_pembiusan"
                                    />
                                    <div class="ml-2" v-if="row.aranpap_riwayat_kel_permasalahan_pembiusan == 'Y'">
                                      <b-form-input v-model="row.aranpap_riwayat_kel_permasalahan_pembiusan_text" style="width: 250px;" type="text" class="form-control" placeholder="Jelaskan"
                                        fdprocessedid="9kl27u" />
                                    </div>
                                  </div>
                                  <VValidate 
                                    name="Permasalahan dalam pembiusan" 
                                    v-model="row.aranpap_riwayat_kel_permasalahan_pembiusan" 
                                    :rules="toValidate(mrValidation.aranpap_riwayat_kel_permasalahan_pembiusan)"
                                  />
                                  <VValidate 
                                    v-if="row.aranpap_riwayat_kel_permasalahan_pembiusan == 'Y'"
                                    name="Jelaskan Permasalahan dalam pembiusan" 
                                    v-model="row.aranpap_riwayat_kel_permasalahan_pembiusan_text" 
                                    :rules="toValidate(mrValidation.aranpap_riwayat_kel_permasalahan_pembiusan_text)"
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td>Operasi jantung coroner </td>
                                <td>
                                  <div class="d-flex" style="align-items: center;">
                                    <b-form-radio-group
                                      class="radio-inline"
                                      :options="Config.mr.yesNoOptV2"
                                      v-model="row.aranpap_riwayat_kel_operasi_jantung"
                                    />
                                    <div class="ml-2" v-if="row.aranpap_riwayat_kel_operasi_jantung == 'Y'">
                                      <b-form-input v-model="row.aranpap_riwayat_kel_operasi_jantung_text" style="width: 250px;" type="text" class="form-control" placeholder="Jelaskan"
                                        fdprocessedid="9kl27u" />
                                    </div>
                                  </div>
                                  <VValidate 
                                    name="Operasi jantung coroner" 
                                    v-model="row.aranpap_riwayat_kel_operasi_jantung" 
                                    :rules="toValidate(mrValidation.aranpap_riwayat_kel_operasi_jantung)"
                                  />
                                  <VValidate 
                                    v-if="row.aranpap_riwayat_kel_operasi_jantung == 'Y'"
                                    name="Jelaskan Operasi jantung coroner" 
                                    v-model="row.aranpap_riwayat_kel_operasi_jantung_text" 
                                    :rules="toValidate(mrValidation.aranpap_riwayat_kel_operasi_jantung_text)"
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td>Diabetes </td>
                                <td>
                                  <div class="d-flex" style="align-items: center;">
                                    <b-form-radio-group
                                      class="radio-inline"
                                      :options="Config.mr.yesNoOptV2"
                                      v-model="row.aranpap_riwayat_kel_diabetes"
                                    />
                                    <div class="ml-2" v-if="row.aranpap_riwayat_kel_diabetes == 'Y'">
                                      <b-form-input v-model="row.aranpap_riwayat_kel_diabetes_text" style="width: 250px;" type="text" class="form-control" placeholder="Jelaskan"
                                        fdprocessedid="9kl27u" />
                                    </div>
                                  </div>
                                  <VValidate 
                                    name="Diabetes" 
                                    v-model="row.aranpap_riwayat_kel_diabetes" 
                                    :rules="toValidate(mrValidation.aranpap_riwayat_kel_diabetes)"
                                  />
                                  <VValidate 
                                    v-if="row.aranpap_riwayat_kel_diabetes == 'Y'"
                                    name="Jelaskan Diabetes" 
                                    v-model="row.aranpap_riwayat_kel_diabetes_text" 
                                    :rules="toValidate(mrValidation.aranpap_riwayat_kel_diabetes_text)"
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td>Penyakit Berat Lainnya </td>
                                <td>
                                  <div class="d-flex" style="align-items: center;">
                                    <b-form-radio-group
                                      class="radio-inline"
                                      :options="Config.mr.yesNoOptV2"
                                      v-model="row.aranpap_riwayat_kel_penyakit_berat"
                                    />
                                    <div class="ml-2" v-if="row.aranpap_riwayat_kel_penyakit_berat == 'Y'">
                                      <b-form-input v-model="row.aranpap_riwayat_kel_penyakit_berat_text" style="width: 250px;" type="text" class="form-control" placeholder="Jelaskan"
                                        fdprocessedid="9kl27u" />
                                    </div>
                                  </div>
                                  <VValidate 
                                    name="Penyakit Berat Lainnya" 
                                    v-model="row.aranpap_riwayat_kel_penyakit_berat" 
                                    :rules="toValidate(mrValidation.aranpap_riwayat_kel_penyakit_berat)"
                                  />
                                  <VValidate 
                                    v-if="row.aranpap_riwayat_kel_penyakit_berat == 'Y'"
                                    name="Jelaskan Penyakit Berat Lainnya" 
                                    v-model="row.aranpap_riwayat_kel_penyakit_berat_text" 
                                    :rules="toValidate(mrValidation.aranpap_riwayat_kel_penyakit_berat_text)"
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>


                  </div>
                </div>
              </div>
              
              <!-- KHUSUS PEREMPUAN -->
              <div class="col-12 mt-2" v-if="row.ap_gender == 2">
                <div class="card mb-0">
                  <div class="card-header bg_head_panel">
                    <h6 class="card-title font-weight-semibold">Khusus Pasien Perempuan</h6>
                  </div>
                  <div class="card-body p-3">
                    <div class="row">
                      <div class="col-md-3">
                        <div>
                          <label for="perHub"> Jumlah Kehamilan:</label>
                          <b-form-input :formatter="numberOnly" v-model="row.aranpap_jumlah_kehamilan" style="width: 250px;" type="text" class="form-control"/>
                        </div>
                      </div>

                      <div class="col-md-3">
                        <div>
                          <label for="perHub"> Jumlah Anak:</label>
                          <b-form-input :formatter="numberOnly" v-model="row.aranpap_jumlah_anak" style="width: 250px;" type="text" class="form-control"/>
                        </div>
                      </div>

                      <div class="col-md-3">
                        <div>
                          <label for="perHub"> Menstruasi Terakhir:</label>
                          <div class="input-group">
                            <div class="input-group-prepend">
                              <span class="input-group-text"><i class="icon-calendar22"></i></span>
                            </div>
                            <datepicker input-class="form-control transparent" class="my-datepicker"
                                calendar-class="my-datepicker_calendar"
                                v-model="row.aranpap_menstruasi_terakhir">
                            </datepicker>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-3">
                        <div>
                          <label for="perHub">Menyusui</label>
                          <b-form-radio-group
                            class="radio-inline"
                            :options="Config.mr.yesNoOptV2"
                            v-model="row.aranpap_menyususi"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <div class="text-right">
              <button type="button" @click="back()" class="btn btn-light mr-3">Back</button>
              <button type="submit" class="btn btn-primary">Submit <i class="icon-paperplane ml-2"></i></button>
            </div>
          </div>
        </div>
        </b-form>
      </validation-observer>
    </div>
  </div>
</template>


<script>
import GlobalVue from '@/libs/Global.vue'
import $ from 'jquery'
const _ = global._
import Gen from '@/libs/Gen.js'
import Datepicker from 'vuejs-datepicker'
import PatientInfo from '@/components/Ranap/PatientInfo.vue'


export default {
  extends: GlobalVue,
  components:{ Datepicker, PatientInfo},
  data(){
    return {
      rowReg: {},
      menuPage: 'RanapKajianPraAnestesiPerawat',
      mPekerjaan: [],
      mAlergi: [],
      
      patientData: {},
      loading: {
          patientInfo: false,
      },
    }
  },
  mounted() {
    this.apiGet() 
    this.getPatientInfo()
  },

  methods: {
    back() {
      this.$router.back()
    },

    toValidate(val){
      return {...val}
    },

    apiGet(params = {}, page = 1){
        if(!this.pageSlug || !this.$route.query.regId){
            this.$router.push({name : 'Dashboard'}).catch(()=>{})
        }

        if(this.pageSlug || this.$route.name=='Dashboard') this.loadingOverlay = true
        this.data.data = false
        let paramsQuery = Object.keys(this.$route.query).length ? this.apiParams : this.$route.query
        let url = this.menuPage+'/'+this.$route.params.pageSlug
        
        Gen.apiRest(
            "/get/"+url, 
            {
            params: Object.assign({page: page}, paramsQuery, params.query||{})
            }
        ).then(res=>{
            this.loadingOverlay = false
            _.forEach(res.data, (v,k)=>{
                this.$set(this, k, v)
            })
            if(!this.isFound){
                this.$router.push({name : 'Dashboard'}).catch(()=>{})
            }
        }).catch(()=>{
            this.loadingOverlay = false
        })

        // master
        if(this.$route.query.regId){
            Gen.apiRest(
                "/get/"+url+'?master=1', 
                {
                params: Object.assign({page: page}, paramsQuery, params.query||{})
                }
            ).then(res=>{
                console.log(res.data)
                _.forEach(res.data, (v,k)=>{
                    this.$set(this, k, v)
                })
            })
        }
        
    },

    
    getPatientInfo() {
      this.$set(this.loading, 'patientInfo', true)
      Gen.apiRest(
      "/do/" + 'RanapPasienPerawatan', {
          data: {
            type: "get-data-pasien",
            id_registrasi: this.$route.query.regId
          }
      },
      "POST"
      ).then(resp => {
      this.$set(this, 'patientData', resp.data.data)

      this.$set(this.loading, 'patientInfo', false)
      })
    },

    getConfigDynamic(master,value){
        let text = ''
        if(value){
            let index = (master||[]).findIndex(x => x.value == value)
            if(index !== -1){
                text = master[index]['text']
            }
        }
        return text
    },
    
    autoSave: _.debounce(function (data) {
        data.type = 'auto-save'
        if(!data.isEdit){
            Gen.apiRest(
                "/do/"+this.menuPage,
                {data:data}, 
                "POST"
            )
        }
    },1000),


    searchAlergi: _.debounce(function (e) {
      let data = {
          name: e,
          type: 'get-alergi'
      }
      Gen.apiRest(
          "/do/"+this.menuPage,
          {data:data}, 
          "POST"
      ).then(res=>{
          this.mAlergi = res.data.data            
      })
    }, 100),
  
    changeAlergi(e){
      if(!(this.row.aranpap_alergi||[]).length){
          this.addAlergi()
      }
    },
    
    addAlergi(){
      this.row.aranpap_alergi.push({
          name : '',
          jenis : null,
      })
      this.addMasterAlergi()
    },
    
    addMasterAlergi(){
      for(let i = 0; i < (this.row.aranpap_alergi||[]).length; i++){
          let newData = this.row.aranpap_alergi[i]
          if((newData.name||"").length && newData.jenis == "Obat"){
              let dataPost = {
                  type : 'auto-save-riwayat',
                  name : newData.name
              }  
              Gen.apiRest(
                  "/do/"+this.menuPage,
                  {data:dataPost}, 
                  "POST"
              )
          }
      }
    },
    
    removeAlergi(k){
      this.row.aranpap_alergi.splice(k,1)
    },
    

    doSubmit(){
      this.$refs['VForm'].validate().then(success => {
        if (!success) {
          return this.$swal({
              icon: 'error',
              title: 'Data Tidak Lengkap, Mohon Lengkapi Data Terlebih Dahulu'
          }).then(result => {
              if (result.value) {
                  setTimeout(() => {
                      let inv = []
                      let el = document.querySelectorAll(".label_error:not([style='display: none;'])")
                      console.log(el)
                      for (let i = 0; i < (el || []).length; i++) {
                          if (el[i].style.display !== 'none') {
                          inv.push(el[i].id)
                          }
                      }
                                  
                      if (inv.length) document.getElementById(inv[0]).scrollIntoView({
                          behavior: 'smooth',
                          block: 'center'
                      })
                  }, 500)
              }
          })
        }
        if (success) {
          this.$swal({
              icon: 'warning',
              title: 'Apakah Anda Yakin akan menyimpan data ini?',
              showCancelButton: true,
              confirmButtonText: 'Ya',
              cancelButtonText: 'Tidak, kembali'
          }).then(result => {
            if (result.value) {
              let data = this.row
              data.type = 'submit-data'
              
              this.loadingOverlay = true
              Gen.apiRest(
              "/do/" + this.menuPage, {
                  data: data
              },
              "POST"
              ).then(res => {
                this.loadingOverlay = false
                
                let resp = res.data
                resp.statusType = 200
                this.$swal({
                    title: resp.message,
                    icon: resp.status,
                    confirmButtonText: 'Ok',
                    allowOutsideClick: false,
                    allowEscapeKey: false
                }).then(result => {
                    this.$router.push({ name: 'RanapOperasiPPJP'}).catch(()=>{})   
                })
              }).catch(err => {
                this.loadingOverlay = false
                if (err) {
                  err.statusType = err.status
                  err.status = "error"
                  err.title = err.response?.data?.title
                  err.message = err.response?.data?.message
                  err.messageError = err.message
                }
                this.doSetAlertForm(err)
              })
            }
          })
        }
      })
    }
  },
  watch:{
    row: {
        handler(v) {
            this.autoSave(v)
        },
        deep: true
    },
    tingkatKesadaranTotal(v){
        setTimeout(()=>{
            this.row.arankm_response_gcs = v
        },250)
    },
  }
}
</script>